<p-accordion>
  <p-accordionTab [selected]="true"
                  header="How to use it">
    <p>Upload files with translations in JSON format. The file name should be in the following format:&nbsp;<code>module.locale.json</code></p>
    <p>Name example:&nbsp;<code>common.en-GB.json</code></p>
    <p>JSON file structure example:&nbsp;<code>{{getFileStructureExample()}}</code></p>
  </p-accordionTab>
</p-accordion>

<p-card header="Import">
  <p-tabView>
    <p-tabPanel header="{{'common.import-json' | translate}}">
      <p-fileUpload
        (onUpload)="onUpload($event)"
        multiple="true"
        name="file"
        url="/api/import-translations/json"
        draggable="true">
        <ng-template let-file
                     pTemplate="file">
          <p class="import-item-title"><b>{{file.name}}</b>
            <p-button icon="pi pi-trash"
                      class="p-button-danger"
                      (onClick)="removeFile($event, file)"></p-button>
          </p>
          <div class="import-item-container">
            <div>
              <p>Module: {{getModuleName(file)}}</p>
              <p>Locale: {{getLocale(file)}}</p>
            </div>
          </div>
          <p-divider></p-divider>
        </ng-template>
      </p-fileUpload>
    </p-tabPanel>
    <!--    <p-tabPanel header="{{'common.import-txt' | translate}}">-->
    <!--      <p-fileUpload name="file" url="/api/import-translations/txt" draggable="true"></p-fileUpload>-->
    <!--    </p-tabPanel>-->
    <!--    <p-tabPanel header="{{'common.import-excel' | translate}}">-->
    <!--      <p-fileUpload name="file" url="/api/import-translations/excel" draggable="true"></p-fileUpload>-->
    <!--    </p-tabPanel>-->
  </p-tabView>
</p-card>
