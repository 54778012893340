import {Component, OnInit, ViewChild} from '@angular/core';
import {LanguageCtrl, TranslationKeyCtrl, TranslationModuleCtrl} from '../../core/sdk/translationmanager-controllers';
import {Language, TranslationKey, TranslationModule} from '../../core/sdk/model-translations';
import {Table} from 'primeng/table';
import {TranslationKeyService} from './translation-key.service';

@Component({
  selector: 'app-translation-management',
  templateUrl: './translation-management.component.html',
  styleUrls: ['./translation-management.component.scss']
})
export class TranslationManagementComponent implements OnInit {

  isLoading = false;
  pageSize = 10;
  pageNumber = 0;
  first = 0;
  countNumber = 0;
  uiKeySearch: string;
  translationModuleSearch: TranslationModule;
  searchInvalid: boolean | undefined = true;
  sortField: string = 'uiKey';
  asc: boolean = true;

  translationNameSearch: string;
  languageList: Language[];

  translationData: TranslationKey[];
  translationModulesList: TranslationModule[];
  selectedUiKey: string;
  selectedName: string;
  selectedTranslationModule: TranslationModule;
  selectedLanguage: Language;

  @ViewChild('table') table: Table;

  constructor(private translationKeyCtrl: TranslationKeyCtrl,
              private translationModuleCtrl: TranslationModuleCtrl,
              private languageCtrl: LanguageCtrl,
              private  translationKeyService: TranslationKeyService) {
    this.translationData = [];
    this.searchInvalid = undefined;
    this.translationKeyService.reloaded.asObservable().subscribe( () => {
      this.translationData = this.translationKeyService.getData();
    });
  }


  ngOnInit(): void {
    this.loadLanguages();
    this.reloadTranslations();
    this.loadModules();
  }

  reloadTranslations(): void {
    this.isLoading = true;
    this.count();
    this.translationKeyCtrl.findAllFiltered(
      this.pageSize, this.pageNumber,
      this.uiKeySearch,
      this.translationModuleSearch?.id.split('/', 2)[1],
      this.translationNameSearch,
      this.sortField,
      this.asc,
      this.searchInvalid ? true : undefined)
      .subscribe(value => {
        this.translationKeyService.setData(value);
        this.isLoading = false;
      });
  }

  loadLanguages(): void {
    this.languageCtrl.findAll().subscribe(value => {
      this.languageList = value;
    });
  }

  loadModules(): void {
    this.translationModuleCtrl.findAll().subscribe(value => {
      this.translationModulesList = value;
      if (value.length > 0) {
        this.selectedTranslationModule = value[0];
      }
    });
  }


  findTranslations(): void {
    this.translationKeyCtrl.findAllFiltered(10, 0).subscribe(value1 => {
      console.log(value1);
    });
  }

  count(): void {
    this.translationKeyCtrl.countFiltered(
      this.uiKeySearch,
      this.translationModuleSearch?.id.getId(),
      this.translationNameSearch,
      this.searchInvalid ? true : undefined)
      .subscribe(value => {
        this.countNumber = value;
      });
  }

  paginate(event: any): void {
    this.first = event.first;
    this.pageSize = event.rows;
    this.pageNumber = (event.first / event.rows);
    this.reloadTranslations();
  }

  clearSearch(): void {
    this.translationModuleSearch = null;
    this.translationNameSearch = '';
    this.uiKeySearch = null;
    this.table.sortOrder = 0;
    this.table.sortField = '';
    this.searchInvalid = null;
    this.table.reset();
    this.reloadTranslations();
  }

  customSort(column?: string, order?: any): void {
    this.sortField = column;
    this.asc = order === 1;
    this.reloadTranslations();
  }

  selectInvalid(event: any): void {
    this.searchInvalid = event.checked;
  }
}
