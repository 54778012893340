import {Injectable} from '@angular/core';
import {ModalService} from '../modals/modal.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Alert, AlertType} from '../../shared/components/alert';
import {NotificationBoxService} from '../../shared/services/notification-box.service';
import {ErrorMessageService} from '../../shared/services/error-message.service';
import {ErrorMessage} from './model-dto';


@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  private readonly errorHandlers: ErrorHandler[];

  constructor(private modalService: ModalService,
              private router: Router, private notificationBoxService: NotificationBoxService,
              private errorMessageService: ErrorMessageService) {
    this.errorHandlers = [
      new ErrorHandler('401', new UnauthorizedCondition(), (response => {
        console.error('401 error is detected');
        if (router.url.toString().split('?')[0] !== '/login') {
          this.notificationBoxService.sendAlert(new Alert(AlertType.DANGER, 'common.incorrectCredentials'));
          this.router.navigate(['/login']);
        }
      }))
      , new ErrorHandler(
        '404',
        new NotFoundCondition(),
        (response) => {
          console.error('404 error is detected');
          this.showMessage(response);
        })
      , new ErrorHandler(
        '403',
        new ForbiddenErrorCondition(),
        (response) => {
          console.error('403 error is detected');
          this.showMessage(response);
        })
      , new ErrorHandler(
        '500',
        new InternalServerErrorCondition(),
        (response) => {
          console.error('500 error is detected');
          this.showMessage(response);
        }),
      new ErrorHandler(
        '504',
        new GatewayTimeoutErrorCondition(),
        (response) => {
          console.error('504 error is detected');
          this.showTimeOutMessage(response);
        }
      )
    ];
  }

  /* Return true when the error was handled, false in other case */
  public handleErrors(response: HttpErrorResponse): boolean {
    return this.handleErrorsIfPresent(response);
  }

  private handleErrorsIfPresent(response: HttpErrorResponse): boolean {
    for (const handler of this.errorHandlers) {
      if (handler.handleErrorIfPresent(response)) {
        return true;
      }
    }
    return false;
  }

  public addHandler(handler: ErrorHandler): void {
    const index = this.errorHandlers.findIndex(handlers => handlers.id === handler.id);
    if (index < 0) {
      this.errorHandlers.push(handler);
    }
  }

  public showMessage(response: HttpErrorResponse, handlerCondition?: HandlerCondition): void {
    const errorMessage: ErrorMessage = JSON.parse(response.error) as ErrorMessage;
    this.errorMessageService.setErrorData(errorMessage, response?.status);
    this.router.navigate(['/tm/error-message']);
  }

  public showUiKeyMessage(response: HttpErrorResponse, handlerCondition?: HandlerCondition): void {
    const errorMessage = {message: JSON.parse(response.error)[0].errorUIkey} as ErrorMessage;
    this.errorMessageService.setErrorData(errorMessage, response?.status);
    this.router.navigate(['/tm/error-message']);
  }

  getBtnLabel(state: boolean): string {
    return (state) ? 'Collapse information' : 'Show More';
  }

  showTimeOutMessage(response: HttpErrorResponse): void {
    this.modalService.createDialog('Error Code: ' + response?.status,
      `Caused by: Time was exceeded for https request '${response.url}'`);
    this.modalService.onConfirm()
      .subscribe(
        _ => {
          this.modalService.close();
        });
    this.modalService.onCancel()
      .subscribe(
        _ => {
          this.modalService.close();
        });
    this.modalService.open();
  }
}

export abstract class HandlerCondition {

  public abstract isMetForGivenResponse(response: HttpErrorResponse): boolean;
}

class UnauthorizedCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }
}

class ForbiddenErrorCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 403;
  }
}

class NotFoundCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 404;
  }
}

class InternalServerErrorCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 500;
  }
}

class GatewayTimeoutErrorCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 504;
  }
}

export class ErrorHandler {
  id: string;
  action: (response) => void;
  condition: HandlerCondition;

  public constructor(id: string, condition: HandlerCondition, action: (response: any) => void) {
    this.id = id;
    this.action = action;
    this.condition = condition;
  }

  public handleErrorIfPresent(response: HttpErrorResponse): boolean {
    if (this.condition.isMetForGivenResponse(response)) {
      this.action(response);
      return true;
    }
    return false;
  }

}
