import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AllFunctionalitiesComponent} from './all-functionalities/all-functionalities.component';
import {AssignedFunctionalitiesComponent} from './assigned-functionalities/assigned-functionalities.component';
import {TranslateModule} from '../../core/translations/translate.module';
import {CoreModule} from '../../core/core.module';
import {FormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {RolesManagementService} from './roles-management.service';
import {RoleCtrl} from '../../core/sdk/translationmanager-controllers';
import {RolesManagementComponent} from './roles-management.component';
import {RolesOverviewComponent} from './roles-overview/roles-overview.component';
import {RoleEditComponent} from './role-edit/role-edit.component';


@NgModule({
  declarations: [
    AllFunctionalitiesComponent,
    AssignedFunctionalitiesComponent,
    RolesManagementComponent,
    RolesOverviewComponent,
    RoleEditComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    TranslateModule,
    TableModule,
    CheckboxModule
  ],
  providers: [
    RoleCtrl,
    RolesManagementService
  ]
})
export class RolesManagementModule {
}
