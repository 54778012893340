import {AfterContentChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '../../translations/translate.service';
import {ModalService} from '../modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit, AfterContentChecked {
  @ViewChild('exampleModal', {static: true}) modalBlock: ElementRef;
  @Input() confirmButtonText = 'common.yes';
  @Input() cancelButtonText = 'common.no';
  @Input() id: string;
  @Input() title: string;
  @Input() text: string;
  @Input() hiddenCancelButton = false;
  @Output() confirmClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();


  constructor(private translateService: TranslateService,
              private cdref: ChangeDetectorRef,
              private modalService: ModalService) {
    this.modalService.setReference(this);
  }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  getTranslatedText(text: string): string {
    return (text) ? this.translateService.translate(text) : '';
  }

  resetModal(): void {
    this.confirmButtonText = 'common.yes';
    this.cancelButtonText = 'common.no';
    this.hiddenCancelButton = false;
  }

  open(): void {
    // this.modalBlock.nativeElement.classList.add('show');
    // this.modalBlock.nativeElement.style.display = 'block';
  }

  close(): void {
    // this.modalBlock.nativeElement.classList.remove('show');
    // this.modalBlock.nativeElement.style.display = 'none';

  }

  confirm(): void {
    this.confirmClicked.emit();
  }

  cancel(): void {
    this.cancelClicked.emit();
  }

  isOpenModal(): boolean{
    return this.modalService.isModalOpen();
  }
}
