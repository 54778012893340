import {Component, OnInit} from '@angular/core';
import {TranslationModule} from '../../core/sdk/model-translations';
import {TranslationModuleCtrl} from '../../core/sdk/translationmanager-controllers';
import {Utility} from '../../shared/utilities/utility';

@Component({
  selector: 'app-module-management',
  templateUrl: './module-management.component.html',
  styles: []
})
export class ModuleManagementComponent implements OnInit {

  modulesData: TranslationModule[];
  translationModule: string;

  constructor(private translationModuleCtrl: TranslationModuleCtrl) {
  }

  ngOnInit(): void {
    this.translationModuleCtrl.findAll().subscribe(value => {
      this.modulesData = value;
      this.translationModule = '';
    });
  }

  removeModule(value: TranslationModule): void {
    this.translationModuleCtrl.deleteTranslationModule(Utility.getObjectId(value.id)).subscribe(_ => this.reloadTableData());
  }

  reloadTableData(): void {
    this.translationModuleCtrl.findAll().subscribe(value => {
      this.modulesData = value;
    });
  }

  addNewModule(): void {
    this.translationModuleCtrl.createNewTranslationModule(this.translationModule).subscribe( _ => this.reloadTableData());
  }
}
