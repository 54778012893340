import { Injectable } from '@angular/core';
import {Pageable} from '../../core/pageable/pageable';
import {BehaviorSubject} from 'rxjs';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {RoleCtrl} from '../../core/sdk/translationmanager-controllers';
import {Utility} from '../../shared/utilities/utility';
import {Role} from '../../core/sdk/translationmanager-model';

@Injectable({
  providedIn: 'root'
})
export class RolesManagementService {
  public pageable = new Pageable();
  public isLoading = false;
  private readonly tableData: BehaviorSubject<Role[]>;
  searchCriteriaForClass: SearchCriteriaForClass;

  constructor(private roleCtrl: RoleCtrl) {
    this.tableData = new BehaviorSubject<Role[]>([]);
  }

  public getTableData(): BehaviorSubject<Role[]> {
    this.loadPaginatedRoles();
    return this.tableData;
  }


  public loadPaginatedRoles(): void {
    this.isLoading = true;
    this.roleCtrl.getRolesFiltered(
      this.searchCriteriaForClass,
      this.pageable.pageNumber,
      this.pageable.pageSize).subscribe((roles: Role[]) => {
        this.tableData.next(roles);
        this.isLoading = false;
      }
    );
  }

  public countRolesFiltered(): void {
    this.roleCtrl.getRolesCountFiltered(
      this.searchCriteriaForClass).subscribe((rolesAmount: number) => {
        this.pageable.count = rolesAmount;
      }
    );

  }

  getEmptyRole(): Role {
    return {
      persons: [],
      personList: [],
      id: 'role/' + Utility.getUUID(),
      version: 0,
      timestamp: 0,
      name: '',
      functionalities: []
    };
  }

}
