<div class="global-align__align-content-start">
  <h1>{{"roles-management.details" | translate}}</h1>
  <h2>{{"roles-management.name" | translate}}</h2>
  <input class="global-input" type="text" name="name" [(ngModel)]="this.role!.name">
</div>
<div class="groups__functionalities" *ngIf="role">
  <div class="groups__functionalities_tables">
    <div class="groups__table_name"><h2>{{'roles-management.not-assigned-functionalities' | translate}}</h2></div>
    <app-all-functionalities></app-all-functionalities>
  </div>
  <!-- (Un)Assign buttons -->
  <div class="groups__btn">
    <div class="groups__row margin-small">
      <i class="pi pi-arrow-left pe-arrow-button" (click)="unAssign()"></i>
    </div>
    <div class="groups__row margin-small">
      <i class="pi pi-arrow-right pe-arrow-button" (click)="assign()"></i>
    </div>
  </div>
  <div class="groups__functionalities_tables">
    <div class="groups__table_name"><h2>{{'roles-management.assigned-functionalities' | translate}}</h2></div>
    <app-assigned-functionalities></app-assigned-functionalities>
  </div>
</div>
