<div
  class={{alert.getClassOfAlert()}}
>
  <div class="notification-wrap">
    <div class="notification-message">
      {{ alert.message }}
    </div>
    <button
      type="button"
      class="notification-close"
      aria-label="Close"
      (click)="deleteNotification()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
