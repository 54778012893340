import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {SecurityCtrl} from '../sdk/translationmanager-controllers';
import {NewPassword, PasswordChange} from '../sdk/model-dto';

@Injectable()
export class NewPasswordService {
  constructor(private httpService: HttpClient,
              private authService: AuthService,
              private securityCtrl: SecurityCtrl) {
  }

  // TODO error and success handling
  public setNewPassword(token: string, password: string): void {
    const newPassword: NewPassword = {token, password};
    this.securityCtrl.newPassword(newPassword)
      .subscribe(() => {
      });
  }

  public changeCurrentPassword(username: string, oldPassword: string, newPassword: string): void {
    const passwordChange: PasswordChange = {username, oldPassword, newPassword};
    this.securityCtrl.changePassword(passwordChange)
      .subscribe(_ => {
      });
  }
}
