<p-table #dt [value]="data" dataKey="id"
         (onPage)="paginate($event)"
         [paginator]="true"
         [lazy]="true"
         [loading]="allFunctionalitiesService.isLoading"
         [first]="allFunctionalitiesService.pageable.pageNumber"
         [rows]="allFunctionalitiesService.pageable.pageSize"
         [totalRecords]="allFunctionalitiesService.pageable.count">
  <ng-template pTemplate="header">
    <tr>
      <th class="primeng-table__search-col"></th>
      <th pSortableColumn="name">{{'roles-management.name' | translate}}
        <p-sortIcon field="name"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th></th>
      <th><input pInputText type="text" (input)="searchByString($event, 'name')"
                 placeholder="{{ 'roles-management.search-by-name' | translate }}" class="primeng-table__filter-col"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-functionality let-expanded="expanded">
    <tr>
      <td>
        <p-checkbox (onChange)="checkboxClicked(functionality)"></p-checkbox>
      </td>
      <td>{{functionality.name}}</td>
    </tr>
  </ng-template>
</p-table>

