import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Language} from '../sdk/model-translations';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslationClientService {
  languagesList: LanguageT[];
  translationModulesList: string[];

  constructor(private httpService: HttpClient) {
  }

  public getAllLanguages(): Observable<LanguageT[]> {
    const subject = new Subject<LanguageT[]>();
    this.httpService.get('/assets/languages.json').subscribe(value => {
      const languageTable = [];
      Object.keys(value).forEach(key => {
        languageTable.push({locale: key, localeName: value[key]} as LanguageT);
      });
      this.languagesList = languageTable;
      subject.next(languageTable);
    });
    return subject.asObservable();
  }

  public getAllModules(): Observable<string[]> {
    const subject = new Subject<string[]>();
    this.httpService.get('/assets/translation-modules.json').subscribe(value => {
      this.translationModulesList = value as string[];
      subject.next(value as string[]);
    });
    return subject.asObservable();
  }

  public getTranslationsForModule(moduleName: string, language: string): Observable<any> {
    const subject = new Subject<any>();
    this.httpService.get(`/assets/translations/${moduleName}.${language}.json`).subscribe(value => {
      const translationMap = new Map<string, string>();
      subject.next(value);
    });
    return subject.asObservable();
  }
}

export interface LanguageT {
  locale: string;
  localeName: string;
}


