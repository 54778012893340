import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Language, Translation, TranslationKey, TranslationModule} from '../../../core/sdk/model-translations';
import {TranslationKeyCtrl} from '../../../core/sdk/translationmanager-controllers';
import {NotificationBoxService} from '../../../shared/services/notification-box.service';
import {Alert, AlertType} from '../../../shared/components/alert';
import {TranslationKeyService} from '../translation-key.service';

@Component({
  selector: 'app-translation-edit',
  templateUrl: './translation-edit.component.html',
  styles: []
})
export class TranslationEditComponent implements OnInit {

  @Output()
  translationDeleted = new EventEmitter();

  @Input()
  translationKey: TranslationKey;

  @Input()
  languageList: Language[];

  @Input()
  moduleList: TranslationModule[];
  selectedModule: TranslationModule;

  constructor(private translationKeyCtrl: TranslationKeyCtrl,
              private notificationBoxService: NotificationBoxService,
              private translationKeyService: TranslationKeyService) {
  }

  ngOnInit(): void {
  }

  sortByLanguage() : Translation[] {
    this.translationKey.translations.sort((a, b) => {
      return this.getLocale(a.language) > this.getLocale(b.language) ? 1: -1;
    });
    console.log(this.translationKey.translations);
    return this.translationKey.translations;
  }

  saveTranslation(): void {
    if (this.selectedModule) {
      this.translationKey.translationModule = this.selectedModule;
    }
    this.translationKeyCtrl.updateTranslationKey(this.translationKey).subscribe(value => {
      value.translationModule = this.translationKey.translationModule;
      this.translationKey = value;
      this.notificationBoxService.sendAlert(new Alert(AlertType.SUCCESS, 'common.translation-saved'));
      this.translationKeyService.changeData(this.translationKey);
    });
  }

  getLocaleName(language: Language): string {
    const lang = this.languageList.find(value => value.id === language.id);
    return lang.localeName;
  }

  getLocale(language: Language): string {
    const lang = this.languageList.find(value => value.id === language.id);
    return lang.locale;
  }

  getLanguageLocaleString(language: Language): string {
    const lang = this.languageList.find(value => value.id === language.id);
    return `${lang.localeName} [${lang.locale}]`;
  }

  deleteTranslation(): void {
    this.translationKeyCtrl.deleteTranslationKey(this.translationKey.id.getId()).subscribe(() => {
      this.notificationBoxService.sendAlert(new Alert(AlertType.DANGER, 'common.translation-deleted'));
      this.translationDeleted.emit();
    });
  }
}
