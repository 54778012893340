import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TableModule} from 'primeng/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {
  AddressCtrl,
  FunctionalityCtrl,
  LanguageCtrl,
  LoginCtrl,
  PersonCtrl,
  RoleCtrl,
  SecurityCtrl,
  ThemeCtrl,
  VersionCtrl
} from './sdk/translationmanager-controllers';
import {AuthService} from './auth/auth.service';
import {AuthGuard} from './auth/auth-guard.service';
import {ModalComponent} from './modals/modal/modal.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {NewPasswordComponent} from './new-password/new-password.component';
import {PasswordChangeComponent} from './password-change/password-change.component';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from './translations/translate.module';
import {TranslateService} from './translations/translate.service';
import {TabComponent} from './tabs/tab/tab.component';
import {TabsComponent} from './tabs/tabs/tabs.component';
import {ButtonModule} from 'primeng/button';
import {ContextMenuModule} from 'primeng/contextmenu';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {ProgressBarModule} from 'primeng/progressbar';
import {SliderModule} from 'primeng/slider';
import {ToastModule} from 'primeng/toast';
import {SearchCriteriaService} from './search-criteria.service';
import {CardModule} from 'primeng/card';


@NgModule({
  declarations: [
    LoginComponent,
    PasswordResetComponent,
    ModalComponent,
    NewPasswordComponent,
    LoginComponent,
    PasswordChangeComponent,
    TabComponent,
    TabsComponent
  ],
    imports: [
        BrowserAnimationsModule,
        ButtonModule,
        CalendarModule,
        CommonModule,
        ContextMenuModule,
        DialogModule,
        DropdownModule,
        FormsModule,
        HttpClientModule,
        InputTextModule,
        MultiSelectModule,
        ProgressBarModule,
        SliderModule,
        TableModule,
        ToastModule,
        SharedModule,
        TranslateModule,
        ReactiveFormsModule,
        DropdownModule,
        CardModule
    ],
  exports: [
    TabsComponent,
    TabComponent,
    ModalComponent
  ],
  providers: [
    AuthGuard,
    AuthService,
    FunctionalityCtrl,
    LanguageCtrl,
    LoginCtrl,
    PersonCtrl,
    RoleCtrl,
    VersionCtrl,
    SecurityCtrl,
    TranslateService,
    ThemeCtrl,
    AddressCtrl,
    SearchCriteriaService
  ],
  entryComponents: [ModalComponent]
})
export class CoreModule { }
