import {Component, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent {

  private isSpinnerLoading = false;
  @ViewChild('spinner', {static: true}) spinner: any;

  @Input() set isLoading(value: boolean) {
    this.isSpinnerLoading = value;
    if (value) {
      this.spinner.nativeElement.style.display = 'block';
    } else {
      this.spinner.nativeElement.style.display = 'none';
    }
  }

  @Input() set width(value: string) {
    if (value) {
      this.spinner.nativeElement.style.width = value;
    }
  }

  @Input() set height(value: string) {
    if (value) {
      this.spinner.nativeElement.style.height = value;
    }
  }

  @Input() set borderWidth(value: string) {
    if (value) {
      this.spinner.nativeElement.style.borderWidth = value;
    }
  }

}
