<div class="logo-image"></div>
<div class="login">
  <p-card>
    <div class="__login-container">
      <h1>{{appName}}</h1>
      <div>{{appPurpose}}</div>
      <form class="mt-6" (ngSubmit)="onLogin(formData)" #formData="ngForm">
        <div class="form-group">
          <label for="username" class="__float-left ml-1">{{ 'common.username' | translate }}</label>
          <input type="text" class="form-control" ngModel id="username" name="username" #loginInput required>
        </div>

        <div class="form-group">
          <label for="password" class="__float-left ml-1">{{ 'common.password' | translate}}</label>

          <input type="password" class="form-control" ngModel id="password" name="password" #passwordInput required>
        </div>

        <button pButton class="p-button m-3" type="submit"
                [label]="'common.login' | translate" [disabled]="!formData.valid">
        </button>

        <button
          pButton
          *ngIf="showResetPassword"
          class="p-button-help m-3"
          (click)="onPasswordReset()"
          [label]="'common.forgot-password' | translate">
        </button>
      </form>
    </div>
  </p-card>
</div>
