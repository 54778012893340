import {BaseObject} from '../../core/sdk/translationmanager-model';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationBoxService} from '../services/notification-box.service';
import {Alert, AlertType} from '../components/alert';

const uuid4 = require('uuid/v4');

/** Implementation of global extended interfaces */
String.prototype.isEmpty = function(): boolean {
  return (this.length === 0 || !this.trim());
};

String.prototype.charArray = function(): string[] {
  return (this.isEmpty()) ? [] : [...this];
};

String.prototype.characterLength = function(): number {
  return this.charArray().length;
};

String.prototype.containsUnicode = function(): boolean {
  return this.characterLength() !== this.length;
};

String.prototype.getId = function(): string {
  return this.split('/', 2)[1];
};

Array.prototype.groupBy = function <T, K>(keyGetter: (x: T) => K): Map<K, Array<T>> {
  const map: Map<K, Array<T>> = new Map();
  this.forEach((item) => {
    const key: K = keyGetter(item);
    const collection: Array<T> | undefined = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

Array.prototype.isEmpty = function(): boolean {
  return this.length === 0;
};

Number.thisOrZero = (value: number | null | undefined): number => (value !== undefined && value !== null) ? value : 0;

Number.prototype.toHex = function(): string {
  return this.toString(16);
};

export class Utility {

  static getUUID(): string {
    return uuid4();
  }

  static getObjectId(id: string): string {
    if (id.includes('/')) {
      return id.split('/', 2)[1];
    } else {
      return id;
    }
  }

  static convertTimestampToStringDate(timestamp: number): string {
    return new Date(timestamp).toLocaleDateString();
  }

  static convertStringDateToTimestamp(date: string): number {
    const dateString = date.split('.');
    return new Date(+dateString[2], +dateString[1] - 1, +dateString[0]).getTime();
  }

  static clone(entity: object): object | null {
    if (entity) {
      return Object.assign({}, entity);
    }
    return null;
  }

  static cloneWithType<T>(entity: object): T | null {
    if (entity) {
      return Object.assign({}, entity) as T;
    }
    return null;
  }

  static cloneArray(entities: object[]): object[] | null {
    const entitiesClone: object[] = [];
    if (entities) {
      entities.forEach(entity => {
        entitiesClone.push(Object.assign({}, entity));
      });
      return entitiesClone;
    }
    return null;
  }

  static isNewObject(item: BaseObject): boolean {
    return (item.version === -1);
  }

  static getObjectType(id: string): string {
    if (id.includes('/')) {
      return id.split('/', 2)[0];
    } else {
      return id;
    }
  }

  static isSmallScreen(): boolean {
    const style = getComputedStyle(document.body);
    const width = style.getPropertyValue('--smallScreenWidth');
    return window.innerWidth < +width;
  }

  static getLocaleId(locale: string): string {
    return locale.substring(0, 2);
  }

  /**
   * Method return parsed violation in case when err response contains it
   * or false in other case;
   */
  static hasViolations(errResponse: HttpErrorResponse): boolean | any[] {
    try {
      const parse = JSON.parse(errResponse.error);
      return (parse && parse.length > 0 && parse[0].errorUIkey) ? parse : false;
    } catch (exception) {
      return false;
    }
  }

  static showViolationsIfOccurs<T extends NotificationBoxService>(errResponse: HttpErrorResponse, notificationService: T): void {
    const err = Utility.hasViolations(errResponse);
    if (err) {
      notificationService.sendAlert(new Alert(AlertType.DANGER, err[0].errorUIkey));
    }
  }

  static getObjectKeys(object: object): string[] | null {
    if (!object) {
      return null;
    }
    return Object.keys(object);
  }

  static getCurrentDate(): number[] {
    const date = new Date();
    return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  }

  static enumToMap(enumeration: any): Map<string, string | number> {
    const map = new Map<string, string | number>();
    for (const key in enumeration) {
      if (!isNaN(Number(key))) {
        continue;
      }
      const val = enumeration[key] as string | number;
      if (val !== undefined && val !== null) {
        map.set(key, val);
      }

    }

    return map;
  }

  static getRandomColor(): string {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  static getNegativeColor(color: string): string {
    const newR = 255 - parseInt(color.slice(1, 3), 16);
    const newG = 255 - parseInt(color.slice(3, 5), 16);
    const newB = 255 - parseInt(color.slice(5, 7), 16);
    return '#' + newR.toHex() + newG.toHex() + newB.toHex();
  }

}
