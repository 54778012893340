import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './core/login/login.component';
import {AuthGuard} from './core/auth/auth-guard.service';
import {HomeComponent} from './core/home/home.component';
import {UserSelfManagementComponent} from './components/user-self-management/user-self-management.component';
import {PasswordResetComponent} from './core/password-reset/password-reset.component';
import {NewPasswordComponent} from './core/new-password/new-password.component';
import {UserManagementComponent} from './core/user-management/user-management.component';
import {PasswordChangeComponent} from './core/password-change/password-change.component';
import {RolesManagementComponent} from './components/roles-management/roles-management.component';
import {ErrorMessageComponent} from './components/error-message/error-message.component';
import {TranslationManagementComponent} from './components/translation-management/translation-management.component';
import {LanguageManagementComponent} from './components/language-management/language-management.component';
import {ModuleManagementComponent} from './components/module-management/module-management.component';
import {ExportComponent} from './components/export/export.component';
import {ImportComponent} from './components/import/import.component';
import {EmailOverviewComponent} from './components/email/email-overview/email-overview.component';
import {EmailEditComponent} from './components/email/email-edit/email-edit.component';
import {EmailTemplateResolverService} from './components/email/email-edit/email-template-resolver.service';


const routes: Routes = [
  {path: '', redirectTo: 'tm', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {
    path: 'tm', component: HomeComponent, canActivate: [AuthGuard], children: [
      {path: 'roles-management', component: RolesManagementComponent},
      {path: 'translation-management', component: TranslationManagementComponent},
      {path: 'language-management', component: LanguageManagementComponent},
      {path: 'module-management', component: ModuleManagementComponent},
      {path: 'export-module', component: ExportComponent},
      {path: 'import-module', component: ImportComponent},
      {path: 'email-module', component: EmailOverviewComponent},
      {path: 'email-template', component: EmailEditComponent, resolve: {emailTemplate: EmailTemplateResolverService}},
      {path: 'profile', component: UserSelfManagementComponent},
      {
        path: 'users-management', children: [
          {path: '', component: UserManagementComponent},
          {path: 'create-user', component: UserManagementComponent}
        ]
      },
      {path: 'password-change', component: PasswordChangeComponent},
      {path: 'error-message', component: ErrorMessageComponent},
    ]
  },
  {path: 'password-reset', component: PasswordResetComponent},
  {path: 'new-password', component: NewPasswordComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
