import {Component, OnInit} from '@angular/core';
import {NewPasswordService} from './new-password.service';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationBoxService} from '../../shared/services/notification-box.service';
import {TranslateService} from '../translations/translate.service';
import {Alert, AlertType} from '../../shared/components/alert';

@Component({
  selector: 'app-new-password',
  templateUrl: 'new-password.component.html'
})
export class NewPasswordComponent implements OnInit {
  private token: string;
  form: FormGroup;

  constructor(private newPasswordService: NewPasswordService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private notificationBoxService: NotificationBoxService,
              private translateService: TranslateService) {
    this.form = new FormGroup({
      password: new FormControl(null, Validators.required),
      passwordConfirm: new FormControl(null,  [
        Validators.required,
        this.matchValues('password'),
      ]),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params[`token`];
    });
  }

  public matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  public onSubmit(): void {
    const password = this.form.value.password;
    this.newPasswordService.setNewPassword(this.token, password);
    this.router.navigate(['/login']).then(() => {
      this.notificationBoxService.sendAlert(new Alert(AlertType.SUCCESS,
        this.translateService.translate('common.password-reset-successful')));
    });
  }
}
