import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordResetService} from './password-reset.service';
import {Router} from '@angular/router';
import {NotificationBoxService} from '../../shared/services/notification-box.service';
import {Alert, AlertType} from '../../shared/components/alert';
import {TranslateService} from '../translations/translate.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: 'password-reset.component.html'
})
export class PasswordResetComponent {
  form: FormGroup;

  constructor(private passwordResetService: PasswordResetService,
              private router: Router,
              private notificationBoxService: NotificationBoxService,
              private translateService: TranslateService) {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  onReset(): void {
    this.passwordResetService.passwordReset(this.form.value.email);
    this.router.navigate(['/login']).then(() => {
      this.notificationBoxService.sendAlert(new Alert(AlertType.SUCCESS, this.translateService.translate('common.password-reset-request-successful')));
    });
  }

  goBack(): void {
    this.router.navigate(['/login']);
  }
}
