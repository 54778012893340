import {Component, ViewChild} from '@angular/core';
import {FileUpload} from "primeng/fileupload";

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent {

  uploadedFiles: any[] = [];
  @ViewChild(FileUpload) fileUpload: FileUpload;

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
      console.log(file);
    }
  }

  getModuleName(file: any) {
    return file.name.split('.')[0];
  }

  getLocale(file: any) {
    return file.name.split('.')[1];
  }

  removeFile($event: any, file: any) {
    this.fileUpload.remove($event, this.fileUpload._files.indexOf(file));
  }

  getFileStructureExample() {
    return `{
    "cancel-button": "Cancel",
    "choose": "Choose",
    "ok": "OK"
    }`;
  }

}
