import {Component, OnInit} from '@angular/core';
import {EmailService} from '../email.service';
import {EmailTemplate} from '../../../core/sdk/model-translations';
import {Router} from '@angular/router';
import {Utility} from '../../../shared/utilities/utility';

@Component({
  selector: 'app-email-table',
  templateUrl: './email-table.component.html',
  styles: []
})
export class EmailTableComponent implements OnInit {
  isLoading = false;
  first = 0;
  pageSize = 5;
  nameFieldFilter: string;

  constructor(public emailService: EmailService, private router: Router) {
    this.emailService.loadData();
  }

  ngOnInit(): void {
  }

  paginate(event: any): void {
    this.first = event.first;
    this.emailService.first = event.first;
    this.emailService.loadData();
  }

  customSort(): void {

  }

  editTemplate(template: EmailTemplate): void {
    this.router.navigate(['tm/email-template'], {queryParams: {id: Utility.getObjectId(template.id)}});
  }
}
