import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslationModule} from "../../../core/sdk/model-translations";

@Component({
  selector: 'app-translation-filter',
  templateUrl: './translation-filter.component.html',
  styleUrls: ['./translation-filter.component.scss']
})
export class TranslationFilterComponent {
  @Input()
  translationModuleSearch: TranslationModule;
  @Output()
  translationModuleSearchChange = new EventEmitter<TranslationModule>();

  @Input()
  translationModulesList: TranslationModule[];

  @Input()
  uiKeySearch: string;
  @Output()
  uiKeySearchChange = new EventEmitter<string>();

  @Input()
  translationTextSearch: string;
  @Output()
  translationTextSearchChange = new EventEmitter<string>();

  @Output()
  searchButtonClick = new EventEmitter<void>();
  @Output()
  clearButtonClick = new EventEmitter<void>();

}
