import {Component, OnInit} from '@angular/core';
import {EmailService} from '../email.service';

@Component({
  selector: 'app-email-add',
  templateUrl: './email-add.component.html',
  styles: []
})
export class EmailAddComponent implements OnInit {
  name: string;

  constructor(private emailService: EmailService) {
  }

  ngOnInit(): void {
  }

  addEmailKey(): void {
    this.emailService.createNewEmailKey(this.name);
    this.name = '';
  }

}
