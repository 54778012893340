import {Injectable} from '@angular/core';
import {EmailKey} from '../../core/sdk/model-translations';
import {Observable, Subject} from 'rxjs';
import {EmailKeyCtrl} from '../../core/sdk/translationmanager-controllers';
import {NotificationBoxService} from '../../shared/services/notification-box.service';
import {Alert, AlertType} from '../../shared/components/alert';
import {CustomCtrlService} from '../../core/sdk/custom-ctrl.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  emailKeyMap: Map<string, EmailKey>;
  reloaded: Subject<void>;
  first = 0;
  pageSize = 5;
  nameFieldFilter: string;
  countNumber = 0;

  constructor(private emailKeyCtrl: EmailKeyCtrl,
              private customCtrlService: CustomCtrlService,
              private notificationBoxService: NotificationBoxService) {
    this.reloaded = new Subject<void>();
    this.emailKeyMap = new Map<string, EmailKey>();
  }

  getData(): EmailKey[] {
    return Array.from(this.emailKeyMap.values());
  }

  loadData(): void {
    this.emailKeyCtrl.getEmailKeyPaginated(this.pageSize, this.first / this.pageSize, this.nameFieldFilter)
      .subscribe((data: EmailKey[]) => {
        data.forEach(value => {
          this.emailKeyMap.set(value.id, value);
        });
        this.reloaded.next();
      });
  }

  createNewEmailKey(name: string): void {
    this.customCtrlService.createLanguage(name).subscribe(() => {
      this.loadData();
      this.notificationBoxService.sendAlert(new Alert(AlertType.SUCCESS, 'common.success'));
    });
  }

  reloadedObservable(): Observable<void> {
    return this.reloaded.asObservable();
  }


}
