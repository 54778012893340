import {Injectable} from '@angular/core';
import {Address, Person} from '../core/sdk/translationmanager-model';
import {Utility} from '../shared/utilities/utility';

@Injectable()
export class LocationService {

  getNewAddress(user: Person, street: string, flat: string, postal: string): Address {
    return {
      flatNumber: flat,
      id: 'address/' + Utility.getUUID(),
      person: user,
      postalCode: postal,
      streetName: street,
      timestamp: 0,
      version: 0,
      contactPerson: '',
      country: '',
      email: '',
      fax: '',
      phone: '',
    };
  }

  getEmptyPersonAddress(personVar: Person): Address {
    return {
      id: 'address/' + Utility.getUUID(),
      version: 0,
      timestamp: 0,
      contactPerson: '',
      country: '',
      email: '',
      fax: '',
      flatNumber: '',
      person: personVar,
      phone: '',
      postalCode: '',
      streetName: '',
    };
  }

}
