import {ComponentFactoryResolver, EventEmitter, Injectable} from '@angular/core';
import {ModalComponent} from './modal/modal.component';
import {NavigationStart, Router} from '@angular/router';
import {filter, take} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modal: ModalComponent;
  private modalOpen = false;

  eventEmitter = new EventEmitter<boolean>();
  eventEmitter2 = new EventEmitter<boolean>();


  constructor(private resolver: ComponentFactoryResolver,
              private router: Router) {
    if (this.modalOpen){
      this.router.events.pipe(filter( e => e instanceof NavigationStart)).subscribe(_ => this.close());
    }
  }


  open(): void {
    this.modalOpen = true;
    this.modal.open();
  }

  close(): void {
    this.modalOpen = false;
    this.modal.resetModal();
    this.modal.close();
  }

  onConfirm(): Observable<any> {
    return this.modal.confirmClicked.pipe(take(1));
  }

  onCancel(): Observable<any> {
    return this.modal.cancelClicked.pipe(take(1));
  }

  onCancelClose(): void {
    this.modal.cancelClicked.pipe(take(1))
      .subscribe(_ => this.close());
  }


  createDialog(titleTranslationKey: string = 'common.information',
               textTranslationKey: string, confirmButtonText?: string, cancelButtonText?: string): void {
    this.passValuesToDialogInstance(titleTranslationKey, textTranslationKey, confirmButtonText, cancelButtonText);
  }

  disableCancelButton(): void {
    this.modal.hiddenCancelButton = true;
  }

  private passValuesToDialogInstance(titleTranslationKey: string, textTranslationKey: string,
                                     confirmButtonText?: string, cancelButtonText?: string): void {
    this.modal.title = titleTranslationKey;
    this.modal.text = textTranslationKey;

    if (confirmButtonText) {
      this.modal.confirmButtonText = confirmButtonText;
    }
    if (cancelButtonText) {
      this.modal.cancelButtonText = cancelButtonText;
    }
  }


  isModalOpen(): boolean {
    return this.modalOpen;
  }

  setReference(modal: ModalComponent): void {
    if (!this.modal){
      this.modal = modal;
    }
  }
}
