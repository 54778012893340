import {AboutComponent} from './components/navibar/about/about.component';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthGuard} from './core/auth/auth-guard.service';
import {AuthService} from './core/auth/auth.service';
import {AuthTokenInterceptor} from './core/auth/auth-token-interceptor';
import {CoreModule} from './core/core.module';
import {FormsModule} from '@angular/forms';
import {HomeComponent} from './core/home/home.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LocationService} from './components/location.service';
import {NavibarComponent} from './components/navibar/navibar.component';
import {NavibarModule} from './components/navibar/navibar.module';
import {Injector, NgModule} from '@angular/core';
import {RolesListComponent} from './components/navibar/roles-list/roles-list.component';
import {SpinnerComponent} from './shared/spinner/spinner.component';
import {UserSelfManagementComponent} from './components/user-self-management/user-self-management.component';
import {PasswordResetService} from './core/password-reset/password-reset.service';
import {NewPasswordService} from './core/new-password/new-password.service';
import {SharedModule} from './shared/shared.module';
import {InjectorClass} from './shared/injectorClass';
import {SearchCriteriaBuilderService} from './components/search-criteria-builder.service';
import {TranslateModule} from './core/translations/translate.module';
import {LanguageSelectorComponent} from './core/translations/language-selector/language-selector.component';
import {BrowserModule} from '@angular/platform-browser';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {ColorPickerModule} from 'primeng/colorpicker';
import {BreadcrumbsComponent} from './components/navibar/breadcrumbs/breadcrumbs.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {TableModule} from 'primeng/table';
import {TreeModule} from 'primeng/tree';
import {MenuModule} from 'primeng/menu';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {DropdownModule} from 'primeng/dropdown';
import {UserManagementModule} from './core/user-management/user-management.module';
import {RolesManagementModule} from './components/roles-management/roles-management.module';
import {ModalService} from './core/modals/modal.service';
import {ErrorMessageComponent} from './components/error-message/error-message.component';
import {TranslationManagementComponent} from './components/translation-management/translation-management.component';
import {
  EmailKeyCtrl,
  EmailTemplateCtrl,
  ExportTranslationsCtrl,
  LanguageCtrl,
  TranslationKeyCtrl,
  TranslationModuleCtrl
} from './core/sdk/translationmanager-controllers';
import {LanguageManagementComponent} from './components/language-management/language-management.component';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {InputTextModule} from 'primeng/inputtext';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {DialogModule} from 'primeng/dialog';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {ToastModule} from 'primeng/toast';
import {ProgressBarModule} from 'primeng/progressbar';
import {TabViewModule} from 'primeng/tabview';
import {CardModule} from 'primeng/card';
import {ModuleManagementComponent} from './components/module-management/module-management.component';
import {TranslationEditComponent} from './components/translation-management/translation-edit/translation-edit.component';
import {CheckboxModule} from 'primeng/checkbox';
import {ExportComponent} from './components/export/export.component';
import {ImportComponent} from './components/import/import.component';
import {FileUploadModule} from 'primeng/fileupload';
import {CommonModule} from '@angular/common';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {TranslationAddComponent} from './components/translation-management/translation-add/translation-add.component';
import {LanguageAddComponent} from './components/language-management/language-add/language-add.component';
import {TranslationKeyService} from './components/translation-management/translation-key.service';
import {EmailEditorModule} from 'angular-email-editor';
import {EmailOverviewComponent} from './components/email/email-overview/email-overview.component';
import {EmailAddComponent} from './components/email/email-add/email-add.component';
import {EmailEditComponent} from './components/email/email-edit/email-edit.component';
import {EmailTableComponent} from './components/email/email-table/email-table.component';
import {TooltipModule} from 'primeng/tooltip';
import {EmailTemplateResolverService} from './components/email/email-edit/email-template-resolver.service';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import { TranslationFilterComponent } from './components/translation-management/translation-filter/translation-filter.component';
import {DividerModule} from "primeng/divider";
import {PanelModule} from "primeng/panel";
import {AccordionModule} from "primeng/accordion";

@NgModule({
  declarations: [
    AppComponent,
    NavibarComponent,
    RolesListComponent,
    HomeComponent,
    AboutComponent,
    UserSelfManagementComponent,
    SpinnerComponent,
    AboutComponent,
    LanguageSelectorComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    ErrorMessageComponent,
    TranslationManagementComponent,
    LanguageManagementComponent,
    ModuleManagementComponent,
    TranslationEditComponent,
    ExportComponent,
    ImportComponent,
    TranslationAddComponent,
    LanguageAddComponent,
    EmailOverviewComponent,
    EmailAddComponent,
    EmailEditComponent,
    EmailTableComponent,
    TranslationFilterComponent
  ],
  imports: [
    AppRoutingModule,
    FontAwesomeModule,
    CoreModule,
    BrowserModule,
    NavibarModule,
    SharedModule,
    FormsModule,
    TranslateModule,
    ColorPickerModule,
    BreadcrumbModule,
    TableModule,
    TreeModule,
    MenuModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    UserManagementModule,
    RolesManagementModule,
    InputTextModule,
    BrowserModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    HttpClientModule,
    TabViewModule,
    FormsModule,
    CardModule,
    CheckboxModule,
    FileUploadModule,
    CommonModule,
    InputTextareaModule,
    EmailEditorModule,
    ProgressSpinnerModule,
    TooltipModule,
    DividerModule,
    PanelModule,
    AccordionModule
  ],
  providers: [
    AuthService,
    PasswordResetService,
    NewPasswordService,
    AuthGuard,
    LocationService,
    SearchCriteriaBuilderService,
    ModalService,
    TranslationKeyCtrl,
    CdkVirtualScrollViewport,
    LanguageCtrl,
    TranslationModuleCtrl,
    ExportTranslationsCtrl,
    EmailKeyCtrl,
    TranslationKeyService,
    EmailTemplateResolverService,
    EmailTemplateCtrl,
    {provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public injector: Injector) {
    InjectorClass.injector = injector;
  }
}
