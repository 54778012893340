<div class="languages">
  <p-card [header]="'common.language-management' | translate">
    <app-language-add (languageAdded)="addNew($event)"></app-language-add>
    <p-table #table [value]="availableLanguages" [scrollable]="true" scrollHeight="500px"
             [virtualScroll]="true" [rows]="100">

      <ng-template pTemplate="header">
        <tr>
          <th>{{'common.locale-name' | translate}}</th>
          <th>{{'common.actions' | translate}}</th>
        </tr>
        <tr>
          <th>
            <div class="p-d-flex">
          <span class="p-input-icon-right" style="width: 100% ">
                                <i class="pi pi-trash"
                                   (click)="filter.value = ''; table.filter(null,'name','contains')"></i>
                                <input #filter pInputText style="width: 100% " type="text"
                                       (input)="table.filter($event.target['value'], 'localeName', 'contains')"
                                       placeholder="{{'common.search-keyword' | translate}}"/>
          </span>
            </div>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-value>
        <tr>
          <td>{{value.localeName}} [{{value.locale}}]</td>
          <td>
            <p-button (click)="removeLanguage(value)">{{'common.delete-language' | translate}}</p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>
