<div class="translation">
  <!--  <p-card header="Translation Management"-->
  <!--          subheader="Total translations: [{{countNumber}}] Pages: [{{Math.ceil(countNumber/pageSize)}}]">-->
  <p-card header="">
    <app-translation-add
      (translationAdded)="reloadTranslations()"
      [translationModulesList]="translationModulesList"></app-translation-add>

    <app-translation-filter
      [(translationModuleSearch)]="translationModuleSearch"
      [(uiKeySearch)]="uiKeySearch"
      [(translationTextSearch)]="translationNameSearch"
      [translationModulesList]="translationModulesList"
      (searchButtonClick)="reloadTranslations()"
      (clearButtonClick)="clearSearch()"></app-translation-filter>
    <p-table #table
             [value]="translationData"
             [paginator]="true"
             [lazy]="true"
             [loading]="isLoading"
             [first]="first"
             [rows]="pageSize"
             (onPage)="paginate($event)"
             [totalRecords]="countNumber"
             [rowsPerPageOptions]="[5,10,15,20,30]"
             (sortFunction)="customSort()"
             [customSort]="true"
             dataKey="id"
             [sortField]="'uiKey'"
             [sortOrder]="1">

      <ng-template pTemplate="header">
        <tr>
          <th (click)="customSort(table.sortField, table.sortOrder)"
              pSortableColumn="module">Translation module
            <p-sortIcon field="module"></p-sortIcon>
          </th>
          <th (click)="customSort(table.sortField, table.sortOrder)"
              pSortableColumn="uiKey">Key
            <p-sortIcon field="uiKey"></p-sortIcon>
          </th>
          <th (click)="customSort(table.sortField, table.sortOrder)"
              pSortableColumn="time">Last update time
            <p-sortIcon field="time"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body"
                   let-value>
        <tr *ngIf="countNumber > 0"
            [pRowToggler]="value">
          <td>{{value.translationModule.name}}</td>
          <td>{{value.uiKey}}</td>
          <td>{{value.timestamp | date : 'medium'}}</td>
        </tr>
        <tr *ngIf="countNumber === 0">
          <td colspan="2">
            No data.
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion"
                   let-value>
        <tr>
          <td colspan="3">
            <app-translation-edit [moduleList]="translationModulesList"
                                  [languageList]="languageList"
                                  [translationKey]="value"
                                  (translationDeleted)="reloadTranslations()"></app-translation-edit>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>
