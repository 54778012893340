import {Component, OnInit} from '@angular/core';
import {AfterViewInit} from '@angular/core';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {OperationSign} from '../../../core/sdk/model-enums';
import {FunctionalityCtrl} from '../../../core/sdk/translationmanager-controllers';
import {Functionality} from '../../../core/sdk/translationmanager-model';
import {AllFunctionalitiesService} from './all-functionalities.service';
import {RoleEditService} from '../role-edit/role-edit.service';

@Component({
  selector: 'app-all-functionalities',
  templateUrl: './all-functionalities.component.html',
  styles: [],
})
export class AllFunctionalitiesComponent implements OnInit, AfterViewInit {
  functionalities: Functionality[];

  pageSize = 5;
  ascending = true;
  data: Functionality[] = [];

  constructor(private functionalityCtrl: FunctionalityCtrl,
              private searchCriteriaService: SearchCriteriaService,
              public allFunctionalitiesService: AllFunctionalitiesService,
              public roleEditService: RoleEditService) {

    this.allFunctionalitiesService.searchCriteriaForClass = this.searchCriteriaService.createSearchCriteriaForClass(
      'Functionality',
      'name',
      true);

  }

  ngAfterViewInit(): void {
    this.allFunctionalitiesService.getTableData().subscribe(result => {
        this.data = result;
      }
    );
    this.allFunctionalitiesService.countFunctionalitiesFiltered();
  }

  ngOnInit(): void {
  }

  getValueFromItem(item: Functionality, column: string): any {
    return item[column];
  }

  checkboxClicked(functionality: Functionality): void {
    if (this.roleEditService.checkedFunctionalities.includes(functionality)) {
      this.roleEditService.unCheckFunctionality(functionality);
    } else {
      this.roleEditService.checkedFunctionalities.push(functionality);
    }
  }

  public refreshTable(): void {
    this.allFunctionalitiesService.loadPaginatedFunctionalities();
    this.allFunctionalitiesService.countFunctionalitiesFiltered();
  }


  public filterValueChange(column: string, value: any, selectedFilter: string): void {
    this.allFunctionalitiesService.searchCriteriaForClass = this.searchCriteriaService.updateSearchCriteria(
      this.allFunctionalitiesService.searchCriteriaForClass, column, value, selectedFilter, false);
    this.refreshTable();
  }

  paginate(event: any): void {
    this.allFunctionalitiesService.pageable.pageNumber = event.first / event.rows;
    this.allFunctionalitiesService.pageable.pageSize = event.rows;
    this.allFunctionalitiesService.loadPaginatedFunctionalities();
  }

  sortByColumn(columnName: string): void {
    this.allFunctionalitiesService.searchCriteriaForClass.sortBy = columnName;
    this.ascending = !this.ascending;
    this.allFunctionalitiesService.searchCriteriaForClass.ascending = this.ascending;
    this.allFunctionalitiesService.loadPaginatedFunctionalities();
  }

  searchByString(data: any, columnName: string): void {
    this.filterValueChange(columnName, data.target.value, Object.keys(OperationSign).indexOf(OperationSign.LIKE.toString()).toString());
  }


}

