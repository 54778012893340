import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';

import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {ThemeCtrl} from '../sdk/translationmanager-controllers';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  showResetPassword: boolean;
  returnUrl: string;
  appName = environment.appName;
  appPurpose = environment.purpose;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private themeCtrl: ThemeCtrl) {
  }

  ngOnInit(): void {
    this.showResetPassword = true;
    this.returnUrl = this.route.snapshot.queryParams[`returnUrl`] || '/tm/translation-management';
    if (this.router.url === '/login') {
    }
  }

  onLogin(form?: NgForm): void {
    const username = form?.value.username;
    const password = form?.value.password;
    this.authService.loginWithRedirect(username, password, this.returnUrl);
  }

  onPasswordReset(): void {
    this.router.navigate(['/password-reset']);
  }
}

