<div class="container-fluid error-message__h90">
  <div class="col-12 error-message">
    <div class="row p-justify-even pt-3 h2">
      {{'common.error-has-occurred' | translate }}
    </div>
    <div class="p-3">
      <div *ngIf="errorMessage">
        <div class="row h4">{{'common.error-code'  | translate }}: {{getErrCode()}}</div>
        <div class="row mt-2 h4">{{'common.error-caused-by'  | translate }}: {{errorMessage.message}}</div>


      <div class="row d-flex p-justify-end mb-1 mt-2">
        <button class="btn btn-primary" (click)="onClickShow()">{{getButtonLabel() | translate}}</button>
        <button class="btn btn-primary ml-2" (click)="onClose()">{{'common.back-to-main-page' | translate}}</button>
      </div>
    </div>
  </div>
</div>
</div>
