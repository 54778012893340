<p-table #dt [value]="data" dataKey="id"
         (onPage)="paginate($event)"
         [paginator]="true"
         [lazy]="true"
         [loading]="rolesManagementService.isLoading"
         [first]="rolesManagementService.pageable.pageNumber"
         [rows]="rolesManagementService.pageable.pageSize"
         [totalRecords]="rolesManagementService.pageable.count">
  <ng-template pTemplate="header">
    <tr>
      <th class="primeng-table__search-col"></th>
      <th (click)="sortByColumn('name')">{{'roles-management.name' | translate}}
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th>{{'roles-management.last-change-date' | translate}}</th>
    </tr>


    <tr>
      <th class="primeng-table__search-col"><i class="primeng-table__search-icon"></i></th>

      <th><input pInputText type="text" (input)="searchByName($event, 'name')"
                 placeholder="{{ 'roles-management.search-by-name' | translate }}" class="primeng-table__filter-col"></th>

      <th></th>
    </tr>


  </ng-template>
  <ng-template pTemplate="body" let-role let-expanded="expanded">
    <tr>
      <td>
        <i class="primeng-table__angle-down" [pRowToggler]="role"
           [ngClass]="expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'"></i>
      </td>
      <td>{{role.name}}</td>
      <td>{{getDateStringFromTimestamp(role.timestamp)}}</td>

    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-role>
    <tr>
      <td class="primeng-table__edit-row-padding" colspan="3">
        <div class="primeng-table__edit-row-component">
          <app-role-edit [role]="role"></app-role-edit>
        </div>
        <div class="primeng-table__edit-row-buttons">
          <button class="fa-button-first-color btn pointer margin" (click)="updateRole(role)">
            {{"roles-management.save" | translate}}
          </button>
          <button class="fa-button-first-color btn pointer margin" (click)="deleteConfirmation(role)">
            {{"roles-management.delete" |
            translate}}
          </button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
