import {ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NotificationBoxService} from '../../services/notification-box.service';
import {Alert} from '../alert';
import {ListAnimationComponent} from '../animation/list/list-animation.component';
import {NotificationComponent} from './notification/notification.component';


@Component({
  selector: 'app-notification-box',
  templateUrl: './notification-box.component.html'
})
export class NotificationBoxComponent extends ListAnimationComponent implements OnInit {
  @ViewChild('notificationBox')
  notificationElem: ElementRef;

  @ViewChildren(NotificationComponent)
  notifications: QueryList<NotificationComponent>;

  public notificationList: Alert[];
  public notificationHeight: number;
  public notificationOffset: number;
  public notificationTTL: number;
  public notificationTTLOffset: number;

  constructor(private notificationBoxService: NotificationBoxService,
              private changeDetection: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.notificationBoxService.notifyListInit.subscribe(() => {
      this.saveNotificationHeight();
      this.changeDetection.detectChanges();
    });
    this.notificationList = this.notificationBoxService.getNotifications();
    this.notificationOffset = 0;
    this.notificationTTL = 4000;
    this.notificationTTLOffset = 650;
  }

  public saveNotificationHeight(): void {
    this.notificationHeight = this.notificationElem.nativeElement.offsetHeight;
  }

  onMouseEnter(): void {
    this.notifications.forEach((notification) => {
      notification.stopTimer(this.notificationTTL);
    });
  }

  onMouseLeave(): void {
    this.notifications.forEach((notification) => {
      notification.startTimer();
    });
  }
}
