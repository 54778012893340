<p-card class="translation-card translation-filter-card"
        header="Filter translations">

  <div class="filter-row">
    <p-dropdown *ngIf="translationModulesList"
                appendTo="body"
                [options]="translationModulesList"
                [(ngModel)]="translationModuleSearch"
                (ngModelChange)="translationModuleSearchChange.emit($event)"
                [showClear]="true"
                optionLabel="name"
                name="translationModuleSearch"
                placeholder="Filter modules">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="translationModuleSearch">
          {{translationModuleSearch.name}}
        </div>
        <div *ngIf="!translationModuleSearch">
          Choose module
        </div>
      </ng-template>
    </p-dropdown>
    <span class="p-input-icon-right">
                  <i *ngIf="uiKeySearch"
                     class="pi pi-trash"
                     (click)="uiKeySearch =''"></i>
                  <input pInputText
                         type="text"
                         [(ngModel)]="uiKeySearch"
                         (ngModelChange)="uiKeySearchChange.emit($event)"
                         placeholder="Filter UI Key"/>
                </span>
            <span class="p-float-label p-ac-center">
              <input id="float-input-translation"
                     type="text"
                     pInputText
                     [(ngModel)]="translationTextSearch"
                     (ngModelChange)="translationTextSearchChange.emit($event)">
              <label for="float-input-translation">Search by translation text</label>
            </span>
    <p-button
      type="button"
      label="Search"
      icon="pi pi-search"
      class="p-button-info"
      (click)="searchButtonClick.emit()"></p-button>
    <p-button
      type="button"
      label="Clear filters"
      icon="pi pi-trash"
      class="p-button-secondary"
      (click)="clearButtonClick.emit()"></p-button>
  </div>
</p-card>
