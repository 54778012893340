import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../core/auth/auth.service';
import {Functionality} from '../../../core/sdk/translationmanager-model';
import {TreeNode} from 'primeng/api';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styles: []
})
export class RolesListComponent implements OnInit {
  functionalities = [];
  treeNodes = [];
  selected: TreeNode;


  constructor(private router: Router,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.functionalities = this.authService.getUserFunctionalities();
    this.treeNodes = this.convertToTreeNode(this.functionalities);
  }

  convertToTreeNode(functionalities: Functionality[]): TreeNode[] {
    const treeNodes = [];
    for (const functionality of functionalities) {
      if (functionality.name) {
        const node = {
          key: functionality.id,
          label: 'common.' + functionality.name,
          data: '/tm/' + functionality.name
        } as TreeNode;
        if (functionality?.childList?.length > 0) {
          const childTreeList = [];
          for (const child of functionality.childList) {
            const childNode = {
              key: child.id,
              label: 'common.' + child.name,
              data: '/tm/' + functionality.name + '/' + child.name,
              type: 'child'
            } as TreeNode;
            childTreeList.push(childNode);
          }
          node.children = childTreeList;
          node.expanded = true;
          node.data = node?.children[0]?.data || node.data;
        } else {
          node.data = '/tm/' + functionality.name;
          node.type = 'child';
        }
        treeNodes.push(node);
      }
    }
    return treeNodes;
  }

  redirectTo(event: { orginalEvent: MouseEvent; node: TreeNode }): void {
    if (event.node.children) {
      this.selected = event.node.children[0];
    } else {
      this.selected = event.node;
    }
    // this.setCurrentFunctionality(this.functionalities[event.node.key]);
    this.router.navigate([event.node.data]);
  }

  getFunctionalityName(functionality: Functionality): string {
    return 'common.' + functionality.name;
  }

  isParent(functionality: Functionality): boolean {
    if (functionality.childList) {
      return functionality.childList.length > 0;
    } else {
      return false;
    }
  }

  // private setCurrentFunctionality(functionality: Functionality):void {
    // if (functionality) {
    //   this.authService.currentFunctionality = functionality;
    //   this.translateService.addFunctionalities(functionality.name);
    // } else if (this.getCurrentPersonRoles() &&
    //   this.getCurrentPersonRoles()[0] &&
    //   this.getCurrentPersonRoles()[0].functionalities[0]) {
    //   this.currentFunctionality = this.getCurrentPersonRoles()[0].functionalities[0];
    //   this.translateService.addFunctionalities(this.getCurrentPersonRoles()[0].functionalities[0].name);
    // }
  // }
}
