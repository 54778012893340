<div class="" style="height: 100%; width: 100%;">
  <app-navibar></app-navibar>
  <div class="home-container">
    <app-sidebar></app-sidebar>
    <div class="outlet">
      <div style="height: 100%; margin-top: 25px;">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

