import {EventEmitter, Injectable} from '@angular/core';
import {Alert} from '../components/alert';

@Injectable({
  providedIn: 'root',
})
export class NotificationBoxService {
  private currentAlertList: Alert[] = [];
  public notifyListInit = new EventEmitter<void>();
  public deleteElem = new EventEmitter<number>();

  public notifyEmit(): void {
    if (this.currentAlertList.length === 1) {
      this.notifyListInit.emit();
    }
  }

  private deleteElemEmit(key: number): void {
    this.deleteElem.emit(key);
  }

  public getNotifications(): Alert[] {
    return this.currentAlertList;
  }

  public deleteNotification(key: number): void {
    this.currentAlertList.splice(key, 1);
    this.deleteElemEmit(key);
  }

  public sendAlert(alert: Alert): void {
    this.currentAlertList.push(alert);
  }
}
