import {Injectable} from '@angular/core';
import {SearchCriteria} from './sdk/model-dto';
import {SearchCriteriaForClass} from './sdk/model-dto';

@Injectable({
  providedIn: 'root'
})
export class SearchCriteriaService {
  constructor() {
  }

  updateSearchCriteria(searchCriteriaForClass: SearchCriteriaForClass,
                       column: string,
                       value: any,
                       selectedFilter: string, multipleCriteriaOnFieldAllowed: boolean): SearchCriteriaForClass {
    if ((this.getSearchCriteriaForField(column, searchCriteriaForClass) === null
      || this.getSearchCriteriaForField(column, searchCriteriaForClass) === undefined) || multipleCriteriaOnFieldAllowed) {
      searchCriteriaForClass.searchCriterias.push(this.createSearchCriteriaForField(column, selectedFilter, value));
    } else {
      const searchForField: any = this.getSearchCriteriaForField(column, searchCriteriaForClass);
      if (searchForField !== undefined) {
        const searchCriteria: SearchCriteria = searchForField;
        const index = searchCriteriaForClass.searchCriterias.indexOf(searchCriteria);
        if (value === '' || value === 0) {
          searchCriteriaForClass.searchCriterias.splice(index, 1);
        } else {
          searchCriteria.criteriaMap[selectedFilter] = value;
          searchCriteriaForClass.searchCriterias[index] = searchCriteria;
        }
      }
    }
    return searchCriteriaForClass;
  }


  public createSearchCriteriaForClass(className: string, sortByVal: string, ascendingVal: boolean): SearchCriteriaForClass {
    return {
      searchCriterias: [],
      filteredClass: className,
      sortBy: sortByVal,
      ascending: ascendingVal
    };
  }

  public getSearchCriteriaForField(column: string, searchCriteriaForClass: SearchCriteriaForClass): SearchCriteria | undefined {
    return searchCriteriaForClass.searchCriterias.find(criteria => criteria.filterField === column);
  }

  public createSearchCriteriaForField(column: string, sign: string, value: string): SearchCriteria {
    return {
      filterField: column,
      criteriaMap: {[sign]: value}
    };
  }

  public removeAllSearchCriteriaForGivenColumn(searchCriteriaForClass: SearchCriteriaForClass, column: string): SearchCriteriaForClass {
    if (this.searchCriteriaForFieldExists(searchCriteriaForClass, column)) {
      searchCriteriaForClass = this.removeSearchCriteriaForGivenColumn(searchCriteriaForClass, column);
      this.removeAllSearchCriteriaForGivenColumn(searchCriteriaForClass, column);
    }
    return searchCriteriaForClass;
  }


  public searchCriteriaForFieldExists(searchCriteriaForClass: SearchCriteriaForClass, column: string): boolean {
    return this.getSearchCriteriaForField(column, searchCriteriaForClass) !== null
      && this.getSearchCriteriaForField(column, searchCriteriaForClass) !== undefined;
  }

  public removeSearchCriteriaForGivenColumn(searchCriteriaForClass: SearchCriteriaForClass, column: string): SearchCriteriaForClass {
    const searchForField: any = this.getSearchCriteriaForField(column, searchCriteriaForClass);
    const index = searchCriteriaForClass.searchCriterias.indexOf(searchForField);
    searchCriteriaForClass.searchCriterias.splice(index, 1);
    return searchCriteriaForClass;
  }

}
