import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../core/auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  authService: AuthService;
  constructor(authService: AuthService) {
    this.authService = authService;
  }
  ngOnInit(): void {
  }

}
