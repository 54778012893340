import {Component} from '@angular/core';
import {animate, animateChild, query, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-list-animation',
  templateUrl: './list-animation.component.html',
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate(600, style({opacity: 1}))
      ])
    ]),
    trigger('fadeOutAnimation', [
      transition(':leave', [
        style({opacity: 1}),
        animate(600, style({opacity: 0}))
      ])
    ]),
    trigger('moveAnimation', [
      state('initial', style({transform: 'translateY(0)'})),
      state('moved', style({transform: 'translateY(-{{elemHeight}}px)'}), {params: {elemHeight: 58}}),
      transition('initial=>moved',
        animate(600))
    ])
  ]
})
export class ListAnimationComponent {
  public currentState = 'initial';

  public changeState(event: any): void {
    this.currentState = this.currentState === 'initial' ? 'moved' : 'initial';
  }
}
