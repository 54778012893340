<div class="form-center">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="form form-standalone">
    <h3>{{'common.provide-new-password' | translate}}</h3>
      <label>{{'common.new-password' | translate}}</label>
      <input required [type]="'password'" formControlName="password">
      <label>{{'common.confirm-password' | translate}}</label>
      <input required [type]="'password'" formControlName="passwordConfirm">
    <div class="form-buttons">
      <button type="submit" [disabled]="form.invalid"  color="primary">{{'common.submit' | translate}}</button>
    </div>
  </form>
</div>
