
export enum OperationSign {
EQUAL,
NOT_EQUAL,
MORE,
LESS,
LIKE,
GREATER_THAN_OR_EQUAL,
LESS_THAN_OR_EQUAL
}

export enum TranslationStatus {
valid
}

