import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../core/auth/auth.service';
import {NotificationBoxService} from '../../shared/services/notification-box.service';
import {Alert, AlertType} from '../../shared/components/alert';
import {TranslateService} from '../../core/translations/translate.service';
import {Router} from '@angular/router';
import {AboutComponent} from './about/about.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-navibar',
  templateUrl: './navibar.component.html',
  styles: []
})
export class NavibarComponent implements OnInit {
  isMenuOpen = false;
  @ViewChild('aboutModal')
  aboutModal: AboutComponent;
  isLoginInfoPresent = false;
  appTitle = environment.appName;
  menuItems = [
    {
      id: 'common.menu',
      label: this.translateService.translate('common.menu'),
      items:
        [
          {
            id: 'common.user-profile',
            label: this.translateService.translate('common.user-profile'),
            command: () => {
              this.router.navigate([`/tm/profile`]);
            }
          },
          {
            id: 'common.about',
            label: this.translateService.translate('common.about'),
            command: () => {
              this.aboutModal.toggleModal();
            }
          },
          {
            id: 'common.logout',
            label: this.translateService.translate('common.logout'),
            command: () => {
              this.authService.logout();
              this.isLoginInfoPresent = false;
            }
          }]
    }];

  constructor(private authService: AuthService,
              private notificationBoxService: NotificationBoxService,
              private translateService: TranslateService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.translateService.translationsLoaded.asObservable().subscribe(() => {
      this.menuItems[0].label = this.translateService.translate(this.menuItems[0].id);
      this.menuItems[0].items.map((value => {
        value.label = this.translateService.translate(value.id);
      }));
      if (!this.isLoginInfoPresent && this.translateService.isTranslationKeyPresent('common.logged-in')
        && this.authService.isAuthenticated()) {
        this.isLoginInfoPresent = true;
        this.notificationBoxService.sendAlert(new Alert(AlertType.SUCCESS, this.translateService.translate('common.logged-in')));
      }
    });
  }
}
