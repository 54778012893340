<div>
  <form (ngSubmit)="onSubmit(formData)" #formData="ngForm">
    <label for="oldPassword"> {{ 'common.old-password' | translate}} </label>
    <div class="login__form-inline ">
      <div>
        <input
          type="password"
          id="oldPassword"
          name="oldPassword"
          ngModel
          class="login__form-control"
          [(ngModel)]="oldPassword"
          required>
      </div>

      <label for="newPassword"> {{ 'common.new-password' | translate }} </label>
      <div>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          ngModel
          class="login__form-control"
          [(ngModel)]="newPassword"
          required>
      </div>
      <div>
        <label for="newPasswordRepeat"> {{ 'common.password-repeat' | translate}} </label>
        <input
          type="password"
          id="newPasswordRepeat"
          name="newPasswordRepeat"
          ngModel
          class="login__form-control"
          [(ngModel)]="newPasswordRepeat"
          required>
      </div>
      <div
        class="new-password-alert new-password-alert-danger"
        *ngIf="!passwordsMatch">
        {{'common.passwords-dont-match' | translate}}
      </div>
      <div
        class="new-password-alert password-reset-alert-success"
        *ngIf="passwordChanged">
        {{'common.password-changed' | translate}}
      </div>
      <button class="btn__standard login__float-right" type="submit"
              [disabled]="!formData.valid">
        <span>{{ 'common.reset-password' | translate}}</span>
      </button>
    </div>
  </form>
</div>
