import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../auth/auth.service';
import {NewPasswordService} from '../new-password/new-password.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html'
})
export class PasswordChangeComponent implements OnInit {
  public newPassword: string;
  public newPasswordRepeat: string;
  public oldPassword: string;
  public passwordsMatch: boolean;
  public passwordChanged: boolean;

  constructor(private authService: AuthService, private newPasswordService: NewPasswordService) {
  }

  ngOnInit(): void {
    this.passwordsMatch = true;
    this.passwordChanged = false;
  }

  public checkPasswords(): boolean {
    return this.newPassword === this.newPasswordRepeat;
  }

  public onSubmit(form: NgForm): void {
    this.passwordsMatch = this.checkPasswords();
    if (this.passwordsMatch) {
      const newPassword = form.value.newPassword;
      const oldPassword = form.value.oldPassword;
      this.newPasswordService.changeCurrentPassword(this.authService.getCurrentUser().userName, oldPassword, newPassword);
    }
  }

}
