import {Component, OnInit} from '@angular/core';
import {ExportTranslationsCtrl} from '../../core/sdk/translationmanager-controllers';
import * as FileSaver from 'file-saver';
import {ExportService} from './export.service';
import {TranslateService} from '../../core/translations/translate.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styles: []
})
export class ExportComponent implements OnInit {
  loading = false;

  constructor(private exportService: ExportService, private translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

  getTranslation(key: string): string {
    return this.translateService.translate(key);
  }

  exportToJson(): void {
    this.loading = true;
    this.exportService.export('json').subscribe(value => {
      const blob = new Blob([value], {
        type: 'application/zip',
      });
      FileSaver.saveAs(blob, 'translations.zip');
      this.loading = false;
    });
  }

  exportToCSV(): void {
    this.loading = true;
    this.exportService.export('csv').subscribe(value => {
      const blob = new Blob([value], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const date = new Date().toLocaleDateString();
      FileSaver.saveAs(blob, `export_translations_${date}.xlsx`);
      this.loading = false;
    });
  }

  exportToSQL(): void {
    this.loading = true;
    this.exportService.export('sql', 'test').subscribe(value => {
      const blob = new Blob([value], {
        type: 'application/zip',
      });
      FileSaver.saveAs(blob, 'translations.zip');
      this.loading = false;
    });
  }

  exportEmails(): void {
    this.loading = true;
    this.exportService.export('emails', 'test').subscribe(value => {
      const blob = new Blob([value], {
        type: 'application/zip',
      });
      FileSaver.saveAs(blob, 'emails.zip');
      this.loading = false;
    });
  }
}
