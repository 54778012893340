import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {EmailKey, EmailTemplate} from './model-translations';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ErrorHandlerService} from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class CustomCtrlService {

  public constructor(private httpService: HttpClient, private errorHandlerService: ErrorHandlerService) {
  }

  public createLanguage(name: string): Observable<EmailKey>  {
    const headers = new HttpHeaders().set('Content-type', 'text/plain');
    const subject = new Subject<EmailKey>();
    this.httpService.post('/api/email-key', name , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
        this.errorHandlerService.handleErrors(error);
        subject.error(error);
      });
    return subject.asObservable();
  }

  public getEmailTemplate(id: string): Observable<string>  {
    return this.httpService.get('/api/email-template/' + id + '', {responseType: 'text'});
  }
}
