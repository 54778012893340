<div class="p-ml-2">
  <p-card header="Add new translations">
    <div class="p-grid p-mt-2">
      <span class="p-float-label p-col-3 p-mr-2" style="">
                    <input style="width: 99%; " id="float-input-locale" type="text" pInputText
                           [(ngModel)]="locale">
                    <label class="p-ml-2" for="float-input-locale">{{'common.locale' | translate}}</label>
      </span>
      <span class="p-float-label p-col-3 p-mr-2" style="">
                    <input style="width: 99%; " id="float-input-locale-name" type="text" pInputText
                           [(ngModel)]="localeName">
                    <label class="p-ml-2" for="float-input-locale-name">{{'common.locale-name' | translate}}</label>
      </span>
      <button class="p-col-3 p-mr-2 p-button-plain" (click)="addLanguage()" pButton [label]="'common.add-language' | translate"></button>
    </div>
  </p-card>
</div>
