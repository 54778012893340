import { Injectable } from '@angular/core';
import {Pageable} from '../../../core/pageable/pageable';
import {BehaviorSubject} from 'rxjs';
import {Functionality} from '../../../core/sdk/translationmanager-model';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {FunctionalityCtrl} from '../../../core/sdk/translationmanager-controllers';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {Utility} from '../../../shared/utilities/utility';
import {OperationSign} from '../../../core/sdk/model-enums';
import {RoleEditService} from '../role-edit/role-edit.service';

@Injectable({
  providedIn: 'root'
})
export class AllFunctionalitiesService {
  public pageable = new Pageable();
  public isLoading = false;
  private readonly tableData: BehaviorSubject<Functionality[]> = new BehaviorSubject<Functionality[]>([]);
  searchCriteriaForClass: SearchCriteriaForClass;

  constructor(private functionalityCtrl: FunctionalityCtrl,
              private searchCriteriaService: SearchCriteriaService,
              public roleEditService: RoleEditService) {
    this.tableData = new BehaviorSubject<Functionality[]>([]);
  }

  public getTableData(): BehaviorSubject<Functionality[]> {
    this.loadPaginatedFunctionalities();
    return this.tableData;
  }



  public loadPaginatedFunctionalities(): void {
    this.prepareSearchCriteriaWithRoleFunctionalities();
    this.isLoading = true;
    this.functionalityCtrl.getFunctionalitiesFiltered(
      this.searchCriteriaForClass,
      this.pageable.pageNumber,
      this.pageable.pageSize).subscribe((functionality: Functionality[]) => {
        this.tableData.next(functionality);
        this.isLoading = false;
      }
    );
  }

  public countFunctionalitiesFiltered(): void {
    this.prepareSearchCriteriaWithRoleFunctionalities();
    this.functionalityCtrl.getFunctionalitiesCountFiltered(
      this.searchCriteriaForClass).subscribe((peopleAmount: number) => {
        this.pageable.count = peopleAmount;
      }
    );

  }

  public addFiltersForNonMembers(column: string, value: any, selectedFilter: string): void {
    this.searchCriteriaForClass = this.searchCriteriaService.updateSearchCriteria(
      this.searchCriteriaForClass, column, value, selectedFilter, true);
  }

  prepareSearchCriteriaWithRoleFunctionalities(): void {
    this.searchCriteriaForClass = this.searchCriteriaService.removeAllSearchCriteriaForGivenColumn(this.searchCriteriaForClass, 'id');
    if (this.roleEditService.assignedFunctionalities) {
      for (const functionality of this.roleEditService.assignedFunctionalities) {
        this.addFiltersForNonMembers('id', Utility.getObjectId(functionality.id),
          Object.keys(OperationSign).indexOf(OperationSign.NOT_EQUAL.toString()).toString());
      }
    }
  }

  public refreshTable(): void {
    this.loadPaginatedFunctionalities();
    this.countFunctionalitiesFiltered();
  }



}
