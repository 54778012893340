<div class="translation-module">
  <p-card [header]="'Module Management'">
    <div>
      <input type="text" [(ngModel)]="translationModule" pInputText style="margin-right: 25px" placeholder="{{'common.module-name' | translate}}"/>
      <button pButton type="button" [label]="'common.module-add' | translate" class="p-button-success" (click)="addNewModule()"></button>
    </div>
    <div>

    </div>
    <br>
    <div>
      <p-table #table [value]="modulesData" [scrollable]="true" scrollHeight="500px"
               [virtualScroll]="true" [rows]="100">

        <ng-template pTemplate="header">
          <tr>
            <th>{{'common.module-name' | translate}}</th>
            <th>{{'common.actions' | translate}}</th>
          </tr>
          <tr>
            <th>
              <div class="p-d-flex">
          <span class="p-input-icon-right" style="width: 100% ">
                                <i class="pi pi-trash"
                                   (click)="filter.value = ''; table.filter(null,'name','contains')"></i>
                                <input #filter pInputText style="width: 100% " type="text"
                                       (input)="table.filter($event.target['value'], 'name', 'contains')"
                                       placeholder="{{'common.search-keyword' | translate}}"/>
          </span>
              </div>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-value>
          <tr>
            <td>{{value.name}}</td>
            <td>
              <p-button (click)="removeModule(value)">{{'common.delete-module' | translate}}</p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-card>
</div>
