import { Component, OnInit } from '@angular/core';
import {Functionality} from '../../../core/sdk/translationmanager-model';
import {RoleEditService} from '../role-edit/role-edit.service';

@Component({
  selector: 'app-assigned-functionalities',
  templateUrl: './assigned-functionalities.component.html',
  styles: [
  ]
})
export class AssignedFunctionalitiesComponent implements OnInit {
  pageSize = 5;

  constructor(public roleEditService: RoleEditService) {
  }

  ngOnInit(): void {
  }

  checkboxClicked(functionality: Functionality): void {
    if (this.roleEditService.checkedAssignedFunctionalities.includes(functionality)) {
      this.roleEditService.unCheckAssignedFunctionality(functionality);
    } else {
      this.roleEditService.checkedAssignedFunctionalities.push(functionality);
    }
  }


}
