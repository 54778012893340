<div class="primeng-table__align-right margin-small" *ngIf="!role">
  <button class="fa-button-first-color btn pointer" (click)="createNewRole()"> {{"roles-management.new" | translate}}</button>
</div>
<app-roles-overview></app-roles-overview>

<div *ngIf="role">
  <app-role-edit [role]="role"></app-role-edit>
  <button class="fa-button-first-color btn pointer margin" (click)="saveNewRole()"> {{"roles-management.save" | translate}}</button>
  <button class="fa-button-first-color btn pointer margin" (click)="cancelCreatingRole()"> {{"roles-management.cancel" | translate}}</button>
</div>
