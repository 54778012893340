import {Component, OnInit} from '@angular/core';
import {TranslateService} from '../translate.service';
import {AuthService} from '../../auth/auth.service';
import {LanguageT} from '../translation-client.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html'
})
export class LanguageSelectorComponent implements OnInit {
  authService: AuthService;
  translateService: TranslateService;
  languages: LanguageT[];

  constructor(translateService: TranslateService, authService: AuthService) {
    this.translateService = translateService;
    this.authService = authService;
  }

  onSelect(newLanguage: any): void {
    this.authService.getCurrentUser().defaultLanguage = newLanguage.value.locale;
    this.translateService.setLanguage(newLanguage.value);
    // this.authService.doUpdateDefaultLanguageForPerson();
  }

  ngOnInit(): void {
    this.translateService.translationsLoaded.subscribe(() => {
      this.languages = Object.values(this.translateService.languages);
    });
  }
}
