<div class="primeng-table__align-right margin-small" *ngIf="!person">
  <button class="fa-button-first-color btn pointer" (click)="createNewPerson()"> {{"user-management.new" | translate}}</button>
</div>
<app-user-table [roles]="allRoles"></app-user-table>

<div *ngIf="person">
  <app-edit-user [person]="person" [roles]="allRoles"></app-edit-user>
  <button class="fa-button-first-color btn pointer margin" (click)="saveNewPerson()"> {{"user-management.save" | translate}}</button>
  <button class="fa-button-first-color btn pointer margin" (click)="cancelCreatingPerson()"> {{"user-management.cancel" | translate}}</button>
</div>
