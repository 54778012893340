import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ErrorHandlerService} from '../../core/sdk/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private httpService: HttpClient, private errorHandlerService: ErrorHandlerService) {
  }

  public export(type: string, databaseType?: string): Observable<any>  {
    const queryParamsList: { name: string, value: string }[] = [];
    if (databaseType !== undefined && databaseType !== null) {
      queryParamsList.push({name: 'database-type', value: databaseType});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const subject = new Subject<any>();
    this.httpService.get('/api/export-translations/' + type + '', {params, responseType: 'arraybuffer'})
      .subscribe(res => subject.next(res), error => {
        this.errorHandlerService.handleErrors(error);
        subject.error(error);
      });
    return subject.asObservable();
  }

}
