<p-table #dt [value]="data" dataKey="id"
         (onPage)="paginate($event)"
         [paginator]="true"
         [lazy]="true"
         [loading]="userManagementService.isLoading"
         [first]="userManagementService.pageable.pageNumber"
         [rows]="userManagementService.pageable.pageSize"
         [totalRecords]="userManagementService.pageable.count">
  <ng-template pTemplate="header">
    <tr>
      <th class="primeng-table__search-col"></th>
      <th (click)="sortByColumn('userName')">{{'user-management.user-name' | translate}}
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th>{{'user-management.name' | translate}}</th>
      <th>{{'user-management.surname' | translate}}</th>
      <th>{{'user-management.user-postal-code' | translate}}</th>
      <th>{{'user-management.street-name' | translate}}</th>
      <th>{{'user-management.flat-number' | translate}}</th>
      <th>{{'user-management.roles' | translate}}</th>
    </tr>


    <tr>
      <th class="primeng-table__search-col"><i class="primeng-table__search-icon"></i></th>

      <th><input pInputText type="text" (input)="searchByName($event, 'userName')"
                 placeholder="{{ 'user-management.search-by-username' | translate }}" class="primeng-table__filter-col"></th>
      <th><input pInputText type="text" (input)="searchByName($event, 'name')"
                 placeholder="{{ 'user-management.search-by-name' | translate }}" class="primeng-table__filter-col"></th>
      <th><input pInputText type="text" (input)="searchByName($event, 'surname')"
                 placeholder="{{ 'user-management.search-by-surname' | translate }}" class="primeng-table__filter-col"></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>


  </ng-template>
  <ng-template pTemplate="body" let-person let-expanded="expanded">
    <tr>
      <td>
        <i class="primeng-table__angle-down" [pRowToggler]="person"
           [ngClass]="expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'"></i>
      </td>
      <td>{{person.userName}}</td>
      <td>{{person.name}}</td>
      <td>{{person.surname}}</td>
      <td>{{getAddress(person, 'postalCode')}}</td>
      <td>{{getAddress(person, 'streetName')}}</td>
      <td>{{getAddress(person, 'flatNumber')}}</td>
      <td>{{getRoles(person.roles)}}</td>

    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-person>
    <tr>
      <td class="primeng-table__edit-row-padding" colspan="8">
        <div class="primeng-table__edit-row-component">
          <app-edit-user [person]="person" [roles]="roles"></app-edit-user>
        </div>
        <div class="primeng-table__edit-row-buttons">
          <button class="fa-button-first-color btn pointer margin" (click)="updatePerson(person)">
            {{"user-management.save" | translate}}
          </button>
          <button class="fa-button-first-color btn pointer margin" (click)="deleteUserDialog(person)">
            {{"user-management.delete" |
            translate}}
          </button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
