import {NgModule} from '@angular/core';
import {DataTranslatePipe} from './data-translate.pipe';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateService} from './translate.service';
import {KeysPipe} from './keys.pipe';
import {TranslatePipe} from './translate.pipe';

@NgModule({
  declarations: [
    DataTranslatePipe,
    KeysPipe,
    TranslatePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    DataTranslatePipe,
    KeysPipe,
    TranslatePipe,
  ],
  providers: [
    TranslateService
  ]
})
export class TranslateModule {}
