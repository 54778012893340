import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {Params} from '@angular/router';

@Injectable()
export class QueryParamsService {
  private queryParams: Map<string, string>;

  constructor(private location: Location) {
    this.queryParams = new Map<string, string>();
    this.loadQueryParamsFromUrl();
  }

  public getKeys(): string[] {
    return Array.from(this.queryParams.keys()).map(key => decodeURIComponent(key));
  }

  public getParameter(key: string): string | undefined {
    const value = this.queryParams.get(key);
    if (value) {
      return decodeURIComponent(value);
    } else {
      return undefined;
    }
  }

  public setParameter(key: string, value: string): void {
    this.queryParams.set(key, value);
    this.reloadUrl();
  }

  public setMultipleParameters(params: { key: string, value: string }[]): void {
    params.forEach(param => {
      this.queryParams.set(param.key, param.value);
    });
    this.reloadUrl();
  }

  public removeParameter(key: string): void {
    this.queryParams.delete(key);
    this.reloadUrl();
  }

  public clearQueryParams(): void {
    this.queryParams.clear();
    this.reloadUrl();
  }

  public reloadUrl(): void {
    this.location.go(this.extractPathWithoutQueryParamsFromURL(), this.convertQueryParamsToURL());
  }

  public loadQueryParamsFromUrl(): void {
    this.queryParams.clear();
    const query = this.location.path().split('?')[1];
    const parameters = this.extractQueryParamsFromURL(query);
    for (const param of parameters) {
      if (param) {
        this.queryParams.set(param.key, decodeURIComponent(param.value));
      }
    }
  }

  private convertQueryParamsToURL(): string {
    let result = '';
    let isFirstQuery = true;
    this.queryParams.forEach((value, key) => {
      if (isFirstQuery) {
        result = `${key}=${value}`;
        isFirstQuery = false;
      } else {
        result = `${result}&${key}=${value}`;
      }
    });
    return result.replace('%2F', '/');
  }

  private extractPathWithoutQueryParamsFromURL(): string {
    return this.location.path().split('?')[0];
  }

  public extractQueryParamsFromURL(query: string): ({ key: string; value: string; } | undefined)[] {
    if (query) {
      const queryParts = query.replace(';', '&').split('&');
      return queryParts.map((part) => {
        const [key, value] = part.split('=');
        if (value !== '' && value !== undefined) {
          return {key, value};
        }
      }).filter((part) => {
        return part !== undefined;
      });
    }
    return [];
  }

  public getDecodedQueryParams(): Params {
    const url = this.location.path();
    const queryParamsMap = this.extractQueryParamsFromURL(url.split('?')[1]);
    const queryParams = {};
    queryParamsMap.forEach(value => {
      if (value) {
        queryParams[value.key] = decodeURI(value.value);
      }
    });
    return queryParams;
  }
}
