import {Injectable} from '@angular/core';
import {Functionality} from '../../../core/sdk/translationmanager-model';

@Injectable({
  providedIn: 'root'
})
export class RoleEditService {
  checkedFunctionalities: Functionality[] = [];
  checkedAssignedFunctionalities: Functionality[] = [];
  public assignedFunctionalities: Functionality[] = [];

  constructor() {
  }


  unCheckAssignedFunctionality(functionality: Functionality): void {
    const index = this.checkedAssignedFunctionalities.indexOf(functionality, 0);
    if (index > -1) {
      this.checkedAssignedFunctionalities.splice(index, 1);
    }
  }

  unCheckFunctionality(functionality: Functionality): void {
    const index = this.checkedFunctionalities.indexOf(functionality, 0);
    if (index > -1) {
      this.checkedFunctionalities.splice(index, 1);
    }
  }
}
