import {Component, OnInit} from '@angular/core';
import {Person, Role} from '../sdk/translationmanager-model';
import {LanguageCtrl, PersonCtrl, RoleCtrl} from '../sdk/translationmanager-controllers';
import {NotificationBoxService} from '../../shared/services/notification-box.service';
import {TranslateService} from '../translations/translate.service';
import {UserManagementService} from './user-management.service';
import {AlertType} from '../../shared/components/alert';
import {Alert} from '../../shared/components/alert';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styles: []
})
export class UserManagementComponent implements OnInit {
  allRoles: Role[] = [];
  person: Person;

  constructor(private languageService: LanguageCtrl,
              private personCtrl: PersonCtrl,
              private roleCtrl: RoleCtrl,
              private translateService: TranslateService,
              private notificationBoxService: NotificationBoxService,
              public userManagementService: UserManagementService) {

}

  ngOnInit(): void {
    this.loadAllRoles();
    this.translateService.loadTranslationModule('user-management');
  }

  private loadAllRoles(): void {
    this.roleCtrl.findAll().subscribe((roles: Role[]) => {
        this.allRoles = roles;
      }
    );
  }

  createNewPerson(): void {
    this.person = this.userManagementService.getNewEmptyUser();
  }

  cancelCreatingPerson(): void {
    delete this.person;
  }

  saveNewPerson(): void {
    const person1 = this.updateRolesWithPerson(this.person);
    this.personCtrl.createObject(person1).subscribe(() => {
      this.userManagementService.getTableData();
      this.userManagementService.countPeopleFiltered();
      this.notificationBoxService.sendAlert(new Alert(AlertType.SUCCESS,
        this.translateService.translate('user-management.person-saved')));
      delete this.person;
    });
  }

  updateRolesWithPerson(person: Person): Person {
    person.roles.forEach(role => {
      if (!role.persons.some(person1 => person1.id === person.id)) {
        role.persons.push(person);
      }
    });
    return person;
  }
}
