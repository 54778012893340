import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VersionCtrl} from '../../../core/sdk/translationmanager-controllers';
import {Version} from '../../../core/sdk/model-dto';
import {Utility} from '../../../shared/utilities/utility';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styles: []
})
export class AboutComponent implements OnInit {
  public modalOpened: boolean;

  @ViewChild('modalAbout') modalAbout: ElementRef;

  public version: Version;

  constructor(private httpClient: HttpClient,
              private versionCtrl: VersionCtrl) {
  }

  ngOnInit(): void {
    this.getVersionFromServer();
    this.modalOpened = false;
  }

  getVersionFromServer(): void {
    this.versionCtrl.getServerVersion().subscribe(data => {
      this.version = data;
    });
  }

  public getObjectKeys(object: object): string[] | null {
    return Utility.getObjectKeys(object);
  }

  public toggleModal(): void {
    this.modalOpened = !this.modalOpened;
  }

}
