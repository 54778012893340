<div class="p-ml-5 p-mt-2">
  <h2>{{translationKey.translationModule.name}}.{{translationKey.uiKey}}</h2>
  <p-dropdown *ngIf="translationKey && moduleList" [options]="moduleList" [(ngModel)]="selectedModule"
              optionLabel="name" name="selectedModule" placeholder="Change module"></p-dropdown>
  <div *ngFor="let translation of sortByLanguage()" class="p-ml-3 p-mt-2">
    <div class="p-grid p-mt-5">
      <span class="p-float-label p-col-5" style="margin-right: 20px">
        <textarea id="textArea" style="min-width: 100%;" pInputTextarea [(ngModel)]="translation.name"></textarea>
          <label for="textArea">{{getLocaleName(translation.language)}} translation</label>
      </span>
      <div class="p-col-3">{{getLanguageLocaleString(translation.language)}}</div>
      <p-checkbox class="p-col-3" label="Is invalid" [(ngModel)]="translation.valid" [binary]="true"></p-checkbox>
    </div>
    <hr>
  </div>
  <div style="float: right;">
    <button pButton type="button" [label]="'common.save-translations' | translate" class="p-button-success p-mr-1"
            (click)="saveTranslation()"></button>
    <button pButton type="button" [label]="'common.delete-translations' | translate" class="p-button-danger"
            (click)="deleteTranslation()"></button>
  </div>
</div>
