import {Injectable} from '@angular/core';
import {Pageable} from '../pageable/pageable';
import {BehaviorSubject} from 'rxjs';
import {SearchCriteriaForClass} from '../sdk/model-dto';
import {Person} from '../sdk/translationmanager-model';
import {PersonCtrl} from '../sdk/translationmanager-controllers';
import {Role} from '../sdk/translationmanager-model';
import {Utility} from '../../shared/utilities/utility';
import {LocationService} from '../../components/location.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  public pageable = new Pageable();
  public isLoading = false;
  private readonly tableData: BehaviorSubject<Person[]>;
  searchCriteriaForClass: SearchCriteriaForClass;

  constructor(private personCtrl: PersonCtrl,
              private locationService: LocationService) {
    this.tableData = new BehaviorSubject<Person[]>([]);
  }

  public getTableData(): BehaviorSubject<Person[]> {
    this.loadPaginatedPeople();
    return this.tableData;
  }


  public loadPaginatedPeople(): void {
    this.isLoading = true;
    this.personCtrl.getPeopleFiltered(
      this.searchCriteriaForClass,
      this.pageable.pageNumber,
      this.pageable.pageSize).subscribe((people: Person[]) => {
        this.tableData.next(people);
        this.isLoading = false;
      }
    );
  }

  public countPeopleFiltered(): void {
    this.personCtrl.getPeopleCountFiltered(
      this.searchCriteriaForClass).subscribe((peopleAmount: number) => {
        this.pageable.count = peopleAmount;
      }
    );

  }

  getNewUser(userName: string,
             name: string,
             surname: string,
             email: string,
             roles: Role[],
             defaultLanguage: string,
             streetName: string,
             flatNumber: string,
             postalCode: string): Person {
    const user: Person = {
      id: 'person/' + Utility.getUUID(),
      defaultLanguage,
      email,
      address: null,
      name,
      roles,
      security: null,
      surname,
      userName,
      timestamp: 0,
      version: 0,
    };
    user.address = this.locationService.getNewAddress(user, streetName, flatNumber, postalCode);
    return user;
  }

  getNewEmptyUser(): Person {
    const user: Person = {
      id: 'person/' + Utility.getUUID(),
      version: 0,
      timestamp: 0,
      userName: '',
      name: '',
      surname: '',
      email: '',
      address: null,
      security: null,
      defaultLanguage: null,
      roles: []
    };
    user.address = this.locationService.getEmptyPersonAddress(user);
    return user;
  }
}
