import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoreModule} from '../core.module';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '../translations/translate.module';
import {TableModule} from 'primeng/table';
import {PersonCtrl} from '../sdk/translationmanager-controllers';
import {UserManagementComponent} from './user-management.component';
import {RoleCtrl} from '../sdk/translationmanager-controllers';
import {UserManagementService} from './user-management.service';
import { EditUserComponent } from './edit-user/edit-user.component';
import {MultiSelectModule} from 'primeng/multiselect';
import { UserTableComponent } from './user-table/user-table.component';



@NgModule({
  declarations: [ UserManagementComponent, EditUserComponent, UserTableComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    TranslateModule,
    TableModule,
    MultiSelectModule

  ], providers: [
    PersonCtrl,
    RoleCtrl,
    UserManagementService
  ]
})
export class UserManagementModule { }
