import { Component, OnInit } from '@angular/core';
import {Input} from '@angular/core';
import {Person} from '../../sdk/translationmanager-model';
import {TranslateService} from '../../translations/translate.service';
import {Role} from '../../sdk/translationmanager-model';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styles: [
  ]
})
export class EditUserComponent implements OnInit {
  @Input() person: Person;
  @Input() roles: Role[];
  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.loadTranslationModule('user-management');
  }

}
