<p-card class="translation-card"
        header="Add new translations">
  <div class="p-d-flex add-form">
    <p-dropdown [options]="translationModulesList"
                [(ngModel)]="selectedTranslationModule"
                optionLabel="name"
                placeholder="Select module"></p-dropdown>
    <span class="p-float-label">
      <textarea id="ui-keys-input"
                [class.translation-keys-input-single-line]="isNewUiKeysSingleLine"
                type="text"
                pInputTextarea
                autoResize="autoResize"
                cols="1"
                [(ngModel)]="selectedUiKey"
                placeholder="Translation keys (use enter to add many at once)"></textarea>
        </span>
    <p-button
      label="Add translations"
      styleClass="p-button-success"
      (click)="addNewTranslation()"></p-button>
  </div>
</p-card>
