<p-table #table [value]="emailService.getData()"
         [paginator]="true"
         [lazy]="true"
         [loading]="isLoading"
         [first]="emailService.first"
         [rows]="emailService.pageSize"
         (onPage)="paginate($event)"
         [totalRecords]="emailService.countNumber"
         [rowsPerPageOptions]="[5,10,15,20,30]"
         (sortFunction)="customSort()"
         [customSort]="true"
         dataKey="id">>
  <ng-template pTemplate="header">
    <tr>
      <th>Key</th>
      <th>Language template</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-value>
    <tr>
      <td>
        {{value.name}}
      </td>
      <td>
        <div *ngFor="let template of value.emailTemplate">
          <button pButton class="p-button-link" (click)="editTemplate(template)"
                  [label]="template.language.localeName" [pTooltip]="'common.edit-template' | translate">
          </button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
