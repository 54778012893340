<p-table #dt [value]="roleEditService.assignedFunctionalities" dataKey="id" [paginator]="true" [rows]="10">
  <ng-template pTemplate="header">
    <tr>
      <th class="primeng-table__search-col"></th>
      <th pSortableColumn="name">{{'roles-management.name' | translate}}
        <p-sortIcon field="name"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th></th>
      <th><input pInputText type="text"  (input)="dt.filter($any($event.target).value, 'name', 'contains')"
                 placeholder="{{ 'roles-management.search-by-name' | translate }}" class="primeng-table__filter-col"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-functionality let-expanded="expanded">
    <tr *ngIf="functionality.parent === null">
      <td><p-checkbox (onChange)="checkboxClicked(functionality)"></p-checkbox></td>
      <td>{{functionality.name}}</td>
    </tr>
  </ng-template>
</p-table>

