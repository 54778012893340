<div class="global-align__align-content-start">
  <h1>{{"user-management.details" | translate}}</h1>
  <p>{{"user-management.user-name" | translate}}</p>
  <input class="global-input" type="text" name="name" [(ngModel)]="this.person!.userName">

  <p>{{"user-management.name" | translate}}</p>
  <input class="global-input" type="text" name="name" [(ngModel)]="this.person!.name">

  <p>{{"user-management.surname" | translate}}</p>
  <input class="global-input" type="text" name="name" [(ngModel)]="this.person!.surname">

  <p>{{"user-management.email" | translate}}</p>
  <input class="global-input" type="text" name="name" [(ngModel)]="this.person!.email">

  <p>{{"user-management.street-name" | translate}}</p>
  <input class="global-input" type="text" name="name" [(ngModel)]="this.person!.address!.streetName">

  <p>{{"user-management.flat-number" | translate}}</p>
  <input class="global-input" type="text" name="name" [(ngModel)]="this.person!.address!.flatNumber">

  <p>{{"user-management.user-postal-code" | translate}}</p>
  <input class="global-input" type="text" name="name" [(ngModel)]="this.person!.address!.postalCode">
  <p>{{"user-management.roles" | translate}}</p>
  <p-multiSelect [options]="roles" defaultLabel="Select" [(ngModel)]="this.person.roles" name="roles" dataKey="id"
                 optionLabel="name">
  </p-multiSelect>
  <br>
</div>

