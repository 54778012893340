<div>
  <p-tree class="roles sidebar"
          selectionMode="single"
          [value]="treeNodes"
          [(selection)]="selected"
          (onNodeSelect)="redirectTo($event)">
    <ng-template let-role pTemplate="child">
      <div>{{role.label | translate}}</div>
    </ng-template>
    <ng-template let-role pTemplate="default">
      <div> {{role.label | translate}}</div>
    </ng-template>
  </p-tree>
</div>
