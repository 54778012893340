<h2>{{"user-self-management.welcome" |translate}} : {{user.userName}}</h2>
<br>
<hr>
<div class="global-justify__center">
  <form #profileForm="ngForm" (ngSubmit)="onSubmit(profileForm)">

    <p>{{"user-self-management.user-name" | translate}}</p>
    <input class="global-input" type="text" name="name" placeholder="{{user.name}}" ngModel>
    <br/>
    <p>{{"user-self-management.user-surname" | translate}}</p>
    <input class="global-input" type="text" name="surname" placeholder="{{user.surname}}" ngModel>
    <br/>
    <p>{{"user-self-management.user-email" | translate}}</p>
    <input class="global-input" type="email" name="email" placeholder="{{user.email}}" ngModel>
    <br/>

    <p>{{'user-self-management.street-name' | translate}}</p>
    <input class="global-input" type="text" name="streetName" placeholder="{{user.address?.streetName}}" ngModel>
    <br/>
    <p>{{"user-self-management.flat-number" | translate}}</p>
    <input class="global-input" type="number" name="flatNumber" placeholder="{{user.address?.flatNumber}}" ngModel>
    <br/>
    <p>{{"user-self-management.user-postal-code" | translate}}</p>
    <input class="global-input" type="text" name="postalCode" placeholder="{{user.address?.postalCode}}" ngModel>
    <br/>
    <br/>
    <input class="button__mat_raised_button" type="submit" value="{{'user-self-management.update' | translate}}">
  </form>
  <br>
</div>
<hr>
<button class="button__mat_raised_button"
        [routerLink]="['/tm/password-change']"> {{"common.password-change" | translate}}</button>

