import {Component, OnInit} from '@angular/core';
import {Input} from '@angular/core';
import {Role} from '../../../core/sdk/translationmanager-model';
import {Functionality} from '../../../core/sdk/translationmanager-model';
import {AllFunctionalitiesService} from '../all-functionalities/all-functionalities.service';
import {RoleEditService} from './role-edit.service';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styles: [],
  providers: [RoleEditService, AllFunctionalitiesService]
})
export class RoleEditComponent implements OnInit {
  @Input() role: Role;

  constructor(public roleEditService: RoleEditService,
              public allFunctionalitiesService: AllFunctionalitiesService) {
  }

  ngOnInit(): void {
    this.roleEditService.assignedFunctionalities = this.role.functionalities;
  }

  assign(): void {
    for (const i of this.roleEditService.checkedFunctionalities) {
      if (!this.role.functionalities.includes(i)) {
        this.role.functionalities.push(i);
        this.roleEditService.unCheckFunctionality(i);
        this.allFunctionalitiesService.refreshTable();
      }
    }
  }

  unAssign(): void {
    for (const i of this.roleEditService.checkedAssignedFunctionalities) {
      if (this.role.functionalities.includes(i)) {
        this.removeFunctionalityFromRole(i);
      }
    }
  }

  removeFunctionalityFromRole(functionality: Functionality): void {

    const index = this.role.functionalities.indexOf(functionality, 0);
    if (index > -1) {
      this.role.functionalities.splice(index, 1);
      this.roleEditService.unCheckAssignedFunctionality(functionality);
      this.allFunctionalitiesService.refreshTable();
    }
  }

}
