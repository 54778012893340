import {Component, OnInit} from '@angular/core';
import {Person} from '../../sdk/translationmanager-model';
import {Role} from '../../sdk/translationmanager-model';
import {OperationSign} from '../../sdk/model-enums';
import {ModalComponent} from '../../modals/modal/modal.component';
import {Alert} from '../../../shared/components/alert';
import {AlertType} from '../../../shared/components/alert';
import {Utility} from '../../../shared/utilities/utility';
import {AfterViewInit} from '@angular/core';
import {LanguageCtrl} from '../../sdk/translationmanager-controllers';
import {PersonCtrl} from '../../sdk/translationmanager-controllers';
import {RoleCtrl} from '../../sdk/translationmanager-controllers';
import {TranslateService} from '../../translations/translate.service';
import {ModalService} from '../../modals/modal.service';
import {NotificationBoxService} from '../../../shared/services/notification-box.service';
import {SearchCriteriaService} from '../../search-criteria.service';
import {UserManagementService} from '../user-management.service';
import {Input} from '@angular/core';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styles: []
})
export class UserTableComponent implements OnInit, AfterViewInit {
  ascending = true;
  data: Person[] = [];
  @Input() roles: Role[];

  constructor(private languageService: LanguageCtrl,
              private personCtrl: PersonCtrl,
              private roleCtrl: RoleCtrl,
              private translateService: TranslateService,
              private modalService: ModalService,
              private notificationBoxService: NotificationBoxService,
              private searchCriteriaService: SearchCriteriaService,
              public userManagementService: UserManagementService) {
    this.userManagementService.searchCriteriaForClass = this.searchCriteriaService.createSearchCriteriaForClass('Person', 'userName', true);
    this.userManagementService.getTableData().subscribe(result => {
        this.data = result;
      }
    );
    this.userManagementService.countPeopleFiltered();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.refreshTable();

  }


  public getAddress(person: Person, propertyName: string): string {
    if (person.address) {
      if (person.address[propertyName]) {
        return person.address[propertyName];
      }
    }
    return '';
  }

  public getRoles(userRoles: Role[]): string {
    if (((userRoles == null)) || (userRoles.length === 0)) {
      return '';
    } else if (userRoles.length === 1) {
      return userRoles[0].name;
    } else {
      let personRoles = '';
      userRoles.forEach(role => {
        personRoles = personRoles + ', ' + role.name;
      });
      if (personRoles.charAt(0) === ',') {
        personRoles = personRoles.substr(2);
      }
      return personRoles;
    }
  }

  public refreshTable(): void {
    this.userManagementService.loadPaginatedPeople();
    this.userManagementService.countPeopleFiltered();
  }


  public filterValueChange(column: string, value: any, selectedFilter: string): void {
    this.userManagementService.searchCriteriaForClass = this.searchCriteriaService.updateSearchCriteria(
      this.userManagementService.searchCriteriaForClass, column, value, selectedFilter, false);
    this.refreshTable();
  }

  searchByName(data: any, columnName: string): void {
    this.filterValueChange(columnName, data.target.value, Object.keys(OperationSign).indexOf(OperationSign.LIKE.toString()).toString());
  }

  paginate(event: any): void {
    this.userManagementService.pageable.pageNumber = event.first / event.rows;
    this.userManagementService.pageable.pageSize = event.rows;
    this.userManagementService.loadPaginatedPeople();
  }

  sortByColumn(columnName: string): void {
    this.userManagementService.searchCriteriaForClass.sortBy = columnName;
    this.ascending = !this.ascending;
    this.userManagementService.searchCriteriaForClass.ascending = this.ascending;
    this.userManagementService.loadPaginatedPeople();
  }


  deleteUserDialog(person: Person): void {
    this.modalService.createDialog('userManagment.deleteTitle',
      'userManagment.deleteQuestion');
    this.modalService.onConfirm()
      .subscribe(
        _ => {
          this.deletePerson(person.id);
          this.modalService.close();
        });
    this.modalService.onCancel()
      .subscribe(
        _ => {
          this.modalService.close();
        });
    this.modalService.open();
  }

  public deletePerson(personID: string): void {
    this.personCtrl.deletePerson(personID.split('/')[1]).subscribe(() => {
        this.notificationBoxService.sendAlert(
          new Alert(AlertType.SUCCESS, this.translateService.translate('user-management.person-deleted')));
        this.refreshTable();
      }
    );
  }

  public updatePerson(person: Person): void {
    const person1 = this.updateRolesWithPerson(person);
    this.personCtrl.update(person1).subscribe(() => {
      this.notificationBoxService.sendAlert(
        new Alert(AlertType.SUCCESS, this.translateService.translate('user-management.person-updated')));
      this.refreshTable();
    }, error => Utility.showViolationsIfOccurs(error, this.notificationBoxService));
  }

  updateRolesWithPerson(person: Person): Person {
    person.roles.forEach(role => {
      if (!role.persons.some(person1 => person1.id === person.id)) {
        role.persons.push(person);
      }
    });
    return person;
  }


}
