import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys',
  pure: false
})
export class KeysPipe implements PipeTransform {

  transform(value, args: string[]): any {
    const keys: string[] = [];
    for (const key of value) {
     keys.push(key);
    }
    return keys;
  }

}
