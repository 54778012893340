<div class="form-center">
  <form (ngSubmit)="onReset()" [formGroup]="form" class="form form-standalone">
    <label>{{'common.password-reset' | translate}}</label>
      <label>{{'common.provide-email-for-reset' | translate}}</label>
      <input required formControlName="email">
    <div class="form-buttons">
      <button type="submit" [disabled]="form.invalid">{{'common.submit' | translate}}</button>
      <button (click)="goBack()">{{'common.cancel' | translate}}</button>
    </div>
  </form>
</div>
