import {Injectable} from '@angular/core';
import {TranslationKey} from '../../core/sdk/model-translations';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationKeyService {
  translationKeys: Map<string, TranslationKey>;
  reloaded: Subject<boolean>;

  constructor() {
    this.reloaded = new Subject<boolean>();
    this.translationKeys = new Map<string, TranslationKey>();
  }


  setData(translationKeys: TranslationKey[]): void {
    this.translationKeys.clear();
    for (const translationKey of translationKeys) {
      this.translationKeys.set(translationKey.id, translationKey);
    }
    this.reloaded.next();
  }

  getData(): TranslationKey[] {
    return Array.from(this.translationKeys.values());
  }

  changeData(translationKey: TranslationKey): void {
    this.translationKeys.set(translationKey.id, translationKey);
    this.reloaded.next();
  }
}
