import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EmailTemplate} from '../../../core/sdk/model-translations';
import {EmailEditorComponent} from 'angular-email-editor';
import {combineLatest, Subject} from 'rxjs';
import {EmailTemplateCtrl} from '../../../core/sdk/translationmanager-controllers';
import {NotificationBoxService} from '../../../shared/services/notification-box.service';
import {Alert, AlertType} from '../../../shared/components/alert';

@Component({
  selector: 'app-email-edit',
  templateUrl: './email-edit.component.html'
})
export class EmailEditComponent implements OnInit {
  emailTemplate: EmailTemplate;
  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private emailTemplateCtrl: EmailTemplateCtrl,
              private notificationBoxService: NotificationBoxService) {
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: { emailTemplate: string }) => {
      this.emailTemplate = JSON.parse(data.emailTemplate);
    });
  }

  editorLoaded(event: any): void {
    if (this.emailTemplate.template !== '') {
      this.emailEditor.editor.loadDesign(JSON.parse(JSON.parse(this.emailTemplate.template)));
    }
  }

  saveEmail(): void {
    const design = new Subject();
    const html = new Subject();
    this.emailEditor.editor.saveDesign((data) => {
      this.emailTemplate.template = JSON.stringify(JSON.stringify(data));
      design.next();
    });
    this.emailEditor.editor.exportHtml((data) => {
      this.emailTemplate.html = data.html;
      html.next();
    });
    combineLatest([design, html]).subscribe(() => {
      this.emailTemplateCtrl.updateObject(this.emailTemplate).subscribe(data => {
        this.emailTemplate = data;
        this.notificationBoxService.sendAlert(new Alert(AlertType.SUCCESS, 'common.success'));
      });
    });
  }
}
