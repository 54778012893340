<div *ngIf="isOpenModal()">
  <div class="modal fa-modal__block-events fade show" style="display: block" tabindex="-1" role="dialog"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{getTranslatedText(title)}}</h5>
        </div>
        <div class="modal-body" *ngIf="text">
          {{getTranslatedText(text)}}
        </div>
        <div class="modal-footer">
          <button *ngIf="!hiddenCancelButton" type="button" class="button button-decline-color" data-dismiss="modal"
                  (click)="cancel()">
            {{getTranslatedText(cancelButtonText)}}
          </button>
          <button type="button" class="button button-confirm-color" (click)="confirm()">
            {{getTranslatedText(confirmButtonText)}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
