<div
  class="notification-box-container"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()">
  <div
    class="notification-box-list"
    id="notification-box"
    #notificationBox>
    <div
      *ngFor="let notification of notificationList; let i = index"
      [@moveAnimation]="{value: currentState, params: {elemHeight: notificationHeight + notificationOffset}}"
      @fadeInAnimation
      (@fadeOutAnimation.start)="changeState($event)"
      (@fadeOutAnimation.done)="changeState($event)"
    >
      <app-notification
        [alert]="notification"
        [key]="i"
        [timeToLive]="notificationTTL + i * notificationTTLOffset">
      </app-notification>
    </div>
  </div>
</div>
