<p-card header="{{'common.export-data' | translate}}">
  <div style="display: flex; justify-content: center;">
    <div *ngIf="!loading" style="width: 100%">
      <button pButton type="button" label="{{'common.export-to-json' | translate}}" class="p-button-help p-mb-2 p-ml-2"
              style="width: 100%"
              (click)="exportToJson()"></button>
      <button pButton type="button" label="{{'common.export-to-excel' | translate}}" class="p-button-help p-mb-2 p-ml-2"
              style="width: 100%"
              (click)="exportToCSV()"></button>
      <button pButton type="button" label="{{'common.export-to-sql' | translate}}" class="p-button-help p-mb-2 p-ml-2"
              style="width: 100%"
              (click)="exportToSQL()"></button>
      <button pButton type="button" label="{{'common.export-emails' | translate}}" class="p-button-help p-mb-2 p-ml-2"
              style="width: 100%"
              (click)="exportEmails()"></button>
    </div>
    <div *ngIf="loading">
      <p-progressSpinner [pTooltip]="'Loading'"></p-progressSpinner>
    </div>
  </div>
</p-card>
