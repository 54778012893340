import {Injector, NgModule} from '@angular/core';
import {NotificationBoxComponent} from './components/notification-box/notification-box.component';
import {NotificationComponent} from './components/notification-box/notification/notification.component';
import {NotificationBoxService} from './services/notification-box.service';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ListAnimationComponent} from './components/animation/list/list-animation.component';
import {InjectorClass} from './injectorClass';
import {QueryParamsService} from './services/query-params.service';
import {MinutesToDurationPipe} from './pipes/minutes-to-duration.pipe';

@NgModule({
  declarations: [
    NotificationBoxComponent,
    NotificationComponent,
    ListAnimationComponent,
    MinutesToDurationPipe
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    NotificationBoxComponent,
    CommonModule,
    FormsModule,
    MinutesToDurationPipe
  ],
  providers: [
    NotificationBoxService,
    QueryParamsService
  ]
})
export class SharedModule {
  constructor(public injector: Injector) {
    InjectorClass.injector = injector;
  }
}
