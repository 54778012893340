import {Injectable} from '@angular/core';
import {OperationSign} from '../core/sdk/model-enums';
import {SearchCriteria} from '../core/sdk/model-dto';

@Injectable()
export class SearchCriteriaBuilderService {
  currentSearchCriteria: { [operationSign: number]: string } = {};
  private currentSearchField: string;
  private currentSearchObject: string;

   getCurrentMapSearchCriteria(): { [operationSign: number]: string } {return this.currentSearchCriteria; }

  getCurrentSearchCriteria(): SearchCriteria {
    return {filterField: this.currentField, criteriaMap: this.getCurrentMapSearchCriteria()};
  }


  get currentField(): string {
    return this.currentSearchField;
  }

  set currentField(value: string) {
    this.currentSearchField = value;
  }

  get currentObject(): string {
    return this.currentSearchObject;
  }

  set currentObject(value: string) {
    this.currentSearchObject = value;
  }

  addSearchPairToCurrentSearchCriteria( sign: OperationSign, value: any): void {this.currentSearchCriteria[sign] = value; }

  flushCurrentSearchCriteria(): void { this.currentSearchCriteria = {}; }
}
