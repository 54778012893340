import {ApplicationRef, Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {LanguageCtrl, TranslationKeyCtrl} from '../sdk/translationmanager-controllers';
import {LanguageT, TranslationClientService} from './translation-client.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  translationsLoaded: BehaviorSubject<boolean>;
  currentLanguage: LanguageT;
  masterLanguage: LanguageT;
  languages: LanguageT[] = [];
  functionalityList: string[] = [];
  translations: Map<string, Map<string, string>>;
  translationsLoadingMap: Map<string, boolean>;
  private lastUpdateTime: Date = new Date();
  private translationsDownloaded = false;
  private translationsLoading = false;
  private defaultLanguage = 'en-GB';

  constructor(private translationClientService: TranslationClientService,
              private ref: ApplicationRef) {
    this.translationsLoaded = new BehaviorSubject<boolean>(false);
    this.translations = new Map<string, Map<string, string>>();
    this.translationsLoadingMap = new Map<string, boolean>();
    this.loadLanguages();
  }

  getCurrentLanguage(): LanguageT {
    return this.currentLanguage;
  }

  getMasterLanguage(): LanguageT {
    return this.masterLanguage;
  }

  loadLanguages(): void {
    this.translationClientService.getAllLanguages().subscribe((newLanguages) => {
      newLanguages.forEach(value => {
        this.languages[value.locale] = value;
      });
      this.masterLanguage = this.languages[this.defaultLanguage];
      const browserLanguage = newLanguages[navigator.language];
      this.currentLanguage = browserLanguage ? browserLanguage : this.masterLanguage;
      for (const lang of newLanguages) {
        if (lang.locale) {
          if (!this.translations[lang.locale]) {
            this.translations[lang.locale] = {};
          }
        }
      }
      this.ref.tick();
      this.translationsLoaded.next(true);
    });
  }

  public addFunctionalities(newFunctionality: string): void {
    if (this.functionalityList.indexOf(newFunctionality) === -1) {
      this.translationsLoadingMap[newFunctionality] = true;
      this.functionalityList.push(newFunctionality);
      this.loadTranslationModule(newFunctionality);
    }
  }

  translateDate(date: Date): string {
    const day: string = date.toString().substring(0, 3);
    const month: string = date.toString().substring(4, 7);
    return this.translate('common.week.' + day.toUpperCase()) + ' ' +
      this.translate('common.month.' + month.toUpperCase()) +
      date.toString().substring(7, 24);
  }

  public loadTranslationModule(functionality: string): Observable<boolean> {
    const moduleLoaded = new Subject<boolean>();
    if (this.currentLanguage && this.currentLanguage.locale && !this.translations[this.currentLanguage.locale][functionality]) {
      this.translationClientService.getTranslationsForModule(functionality, this.currentLanguage.locale)
        .subscribe(translationMap => {
          this.translations[this.currentLanguage.locale][functionality] = translationMap;
          this.translationsLoadingMap[functionality] = false;
          this.translationsLoaded.next(true);
          this.ref.tick();
          moduleLoaded.next(true);
        });
    }
    return moduleLoaded.asObservable();
  }


  setLanguageForLocale(locale: string): void {
    const language = this.languages[locale];
    if (language) {
      this.setLanguage(language);
    }
  }

  setLanguage(newLanguage: LanguageT): void {
    this.currentLanguage = newLanguage;
    if (this.currentLanguage.locale) {
      if (this.translations[this.currentLanguage.locale]) {
        this.functionalityList.forEach(currentFunctionalityName => {
          if (!this.translations[this.currentLanguage.locale][currentFunctionalityName]) {
            this.loadTranslationModule(currentFunctionalityName);
          }
        });
      }
    }
  }

  dataTranslate(tMaps: any): string {
    return '';
  }

  translate(key: string): string {
    if (!this.currentLanguage) {
      return this.defaultMessage(key);
    }
    const translationKeyComponents = key.split('.');
    if (translationKeyComponents.length < 2) {
      return this.defaultMessage(key);
    }
    const [functionality, ...uiKeyList] = translationKeyComponents;
    const uiKey = uiKeyList.join('.');

    if (this.currentLanguage === undefined ||
      !(this.translations[this.currentLanguage.locale][functionality]) &&
      this.currentLanguage.locale && !this.translationsLoadingMap[functionality]) {
      this.addFunctionalities(functionality);
      return this.defaultMessage(key);
    }

    if (this.currentLanguage.locale &&
      this.translations[this.currentLanguage.locale][functionality] &&
      this.translations[this.currentLanguage.locale][functionality][uiKey]) {
      return this.translations[this.currentLanguage.locale][functionality][uiKey];
    }
    // return key when not found
    return this.defaultMessage(key);
  }

  defaultMessage(key: string): string {
    return `[${key}]`;
  }

  isTranslationKeyPresent(key: string): boolean {
    return this.translate(key) !== this.defaultMessage(key);
  }

  getLocaleId(locale: string): string {
    return locale.substring(0, 2);
  }
}
