import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {EmailTemplate} from '../../../core/sdk/model-translations';
import {CustomCtrlService} from '../../../core/sdk/custom-ctrl.service';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateResolverService implements Resolve<string>{

  constructor(private customCtrlService: CustomCtrlService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Promise<string> | string {
    const id = route.queryParamMap.get('id');
    return this.customCtrlService.getEmailTemplate(id);
  }
}
