<nav id="main-navibar" class="navbar sticky-top">
  <div class="container-fluid">
    <div class="navbar__align-content-start">
      <!--      Future App Logo-->
      <h5>{{appTitle}}</h5>

    </div>
    <div class="navbar__align-content-start">
      <app-breadcrumbs></app-breadcrumbs>
    </div>
    <div class="navbar__align-content-end">
      <app-language-selector class="navbar__margin"></app-language-selector>

      <p-button icon="pi pi-bars" (click)="menu.toggle($event)"></p-button>
      <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
    </div>
  </div>

</nav>
<app-about #aboutModal></app-about>
