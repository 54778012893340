import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service';

@Pipe({
  name: 'dataTranslate',
  pure: false
})
export class DataTranslatePipe implements PipeTransform {
  private translateService: TranslateService;

  constructor(translateService: TranslateService) {
    this.translateService = translateService;
  }

  transform(value: any): any {

    if (!value) {
      return;
    }
    return this.translateService.dataTranslate(value);
  }
}
