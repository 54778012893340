import {Component, Input, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslationModule} from '../../../core/sdk/model-translations';
import {Utility} from '../../../shared/utilities/utility';
import {Alert, AlertType} from '../../../shared/components/alert';
import {TranslationKeyCtrl} from '../../../core/sdk/translationmanager-controllers';
import {NotificationBoxService} from '../../../shared/services/notification-box.service';

@Component({
  selector: 'app-translation-add',
  templateUrl: './translation-add.component.html',
  styleUrls: ['./translation-add.component.scss']
})
export class TranslationAddComponent implements OnInit {
  @Input() translationModulesList: TranslationModule[];
  selectedTranslationModule: TranslationModule;
  selectedUiKey: string;
  @Output() translationAdded = new EventEmitter();

  get isNewUiKeysSingleLine(): boolean {
    return !this.selectedUiKey || this.selectedUiKey?.split('\n')?.length <= 1;
  }

  constructor(private translationKeyCtrl: TranslationKeyCtrl,
              private notificationBoxService: NotificationBoxService) {
  }

  ngOnInit(): void {
  }

  addNewTranslation(): void {
    this.translationKeyCtrl.createNewTranslationKey(
      {translationModuleId: Utility.getObjectId(this.selectedTranslationModule.id), uiKey: this.selectedUiKey.split('\n')})
      .subscribe(value => {
        this.translationAdded.emit();
        // this.selectedTranslationModule = undefined;
        // this.selectedUiKey = '';
        this.notificationBoxService.sendAlert(new Alert(AlertType.SUCCESS, 'common.success'));
      });
  }
}
