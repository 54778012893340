import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LanguageDTO} from '../../../core/sdk/translations-dto';

@Component({
  selector: 'app-language-add',
  templateUrl: './language-add.component.html',
  styles: []
})
export class LanguageAddComponent implements OnInit {
  locale: any;
  localeName: any;
  @Output() languageAdded = new EventEmitter<LanguageDTO>();

  constructor() {
  }

  ngOnInit(): void {
  }

  addLanguage(): void {
    this.languageAdded.next({locale: this.locale, localeName: this.localeName});
    this.localeName = '';
    this.locale = '';
  }

}
