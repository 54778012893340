import { Component, OnInit } from '@angular/core';
import {LanguageCtrl} from '../../core/sdk/translationmanager-controllers';
import {TranslateService} from '../../core/translations/translate.service';
import {ModalService} from '../../core/modals/modal.service';
import {NotificationBoxService} from '../../shared/services/notification-box.service';
import {Alert} from '../../shared/components/alert';
import {AlertType} from '../../shared/components/alert';
import {RolesManagementService} from './roles-management.service';
import {Role} from '../../core/sdk/translationmanager-model';
import {RoleCtrl} from '../../core/sdk/translationmanager-controllers';

@Component({
  selector: 'app-roles-management',
  templateUrl: './roles-management.component.html',
  styles: [
  ]
})
export class RolesManagementComponent implements OnInit {
  role: Role;
  constructor(private languageService: LanguageCtrl,
              private translateService: TranslateService,
              private roleCtrl: RoleCtrl,
              private modalService: ModalService,
              private notificationBoxService: NotificationBoxService,
              public rolesManagementService: RolesManagementService) {
  }

  ngOnInit(): void {
    this.translateService.loadTranslationModule('roles-management');
  }



  createNewRole(): void {
    this.role = this.rolesManagementService.getEmptyRole();
  }

  cancelCreatingRole(): void {
    delete this.role;
  }

  saveNewRole(): void {
    this.roleCtrl.createObject(this.role).subscribe(() => {
      this.rolesManagementService.getTableData();
      this.rolesManagementService.countRolesFiltered();
      this.notificationBoxService.sendAlert(new Alert(AlertType.SUCCESS,
        this.translateService.translate('roles-management.role-saved')));
      delete this.role;
    });
  }
}
