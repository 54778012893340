import {Component, OnInit} from '@angular/core';
import {Person} from '../../core/sdk/translationmanager-model';
import {AuthService} from '../../core/auth/auth.service';
import {PersonCtrl} from '../../core/sdk/translationmanager-controllers';
import {NgForm} from '@angular/forms';
import {Alert, AlertType} from '../../shared/components/alert';
import {NotificationBoxService} from '../../shared/services/notification-box.service';
import {Utility} from '../../shared/utilities/utility';
import {TranslateService} from '../../core/translations/translate.service';
import {Address} from '../../core/sdk/translationmanager-model';
import {AddressCtrl} from '../../core/sdk/translationmanager-controllers';
import {LocationService} from '../location.service';

@Component({
  selector: 'app-user-self-management',
  templateUrl: './user-self-management.component.html',
  styles: []
})
export class UserSelfManagementComponent implements OnInit {
  user: Person;

  constructor(private authService: AuthService,
              private personService: PersonCtrl,
              private translateService: TranslateService,
              private locationService: LocationService,
              private addressCtrl: AddressCtrl,
              private notificationBoxService: NotificationBoxService) {
  }

  ngOnInit(): void {
    this.translateService.loadTranslationModule('user-self-management');
    this.user = this.authService.getCurrentUser();
    if (this.user.address) {
      this.addressCtrl.getObject(Utility.getObjectId(this.user.address.id)).subscribe(address => {
        this.user.address = address;
      });
    }
  }

  onSubmit(profileForm: NgForm): void {
    const {name, surname, email, streetName, flatNumber, postalCode} = profileForm.value;
    this.user.name = this.updateValidValues(this.user.name, name);
    this.user.surname = this.updateValidValues(this.user.surname, surname);
    this.user.email = this.updateValidValues(this.user.email, email);
    if (!this.user.address) {
      this.user.address = this.locationService.getNewAddress(this.user, streetName, flatNumber, postalCode);
    } else {
      this.user.address = this.updateExistingLocation(this.user.address, streetName, flatNumber, postalCode);
    }
    this.personService.selfUpdate(this.user).subscribe((response) => {
        this.user = response;
        this.notificationBoxService.sendAlert(new Alert(AlertType.SUCCESS, 'user-self-management.UpdateSuccessful'));
      }, error => Utility.showViolationsIfOccurs(error, this.notificationBoxService)
    );

  }

  updateExistingLocation(address: Address, street: string, flat: string, postal: string): Address {
    address.streetName = this.updateValidValues(address.streetName, street);
    address.flatNumber = flat;
    address.postalCode = this.updateValidValues(address.postalCode, postal);
    if (!address.version) {
      address.version = 1;
    }
    return address;
  }

  updateValidValues(target: string | null, newValue: string): string {
    if (!newValue.isEmpty()) {
      return newValue;
    }
    if (target === null) {
      return '';
    } else {
      return target;
    }
  }
}
