import {Component, OnInit} from '@angular/core';
import {AfterViewInit} from '@angular/core';
import {Output} from '@angular/core';
import {EventEmitter} from '@angular/core';
import {LanguageCtrl} from '../../../core/sdk/translationmanager-controllers';
import {TranslateService} from '../../../core/translations/translate.service';
import {ModalService} from '../../../core/modals/modal.service';
import {NotificationBoxService} from '../../../shared/services/notification-box.service';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {Utility} from '../../../shared/utilities/utility';
import {Alert} from '../../../shared/components/alert';
import {AlertType} from '../../../shared/components/alert';
import {OperationSign} from '../../../core/sdk/model-enums';
import {RolesManagementService} from '../roles-management.service';
import {Role} from '../../../core/sdk/translationmanager-model';
import {RoleCtrl} from '../../../core/sdk/translationmanager-controllers';

@Component({
  selector: 'app-roles-overview',
  templateUrl: './roles-overview.component.html',
  styles: []
})
export class RolesOverviewComponent implements OnInit, AfterViewInit {
  ascending = true;
  data: Role[] = [];
  @Output() groupSelectedEmitter = new EventEmitter<Role | null>();

  constructor(private languageService: LanguageCtrl,
              private translateService: TranslateService,
              private roleCtrl: RoleCtrl,
              private modalService: ModalService,
              private notificationBoxService: NotificationBoxService,
              private searchCriteriaService: SearchCriteriaService,
              public rolesManagementService: RolesManagementService) {
    this.rolesManagementService.searchCriteriaForClass = this.searchCriteriaService.createSearchCriteriaForClass('Role', 'name', true);
    this.rolesManagementService.getTableData().subscribe(result => {
        this.data = result;
      }
    );
    this.rolesManagementService.countRolesFiltered();
  }

  ngOnInit(): void {
    this.translateService.loadTranslationModule('roles-management');
  }


  ngAfterViewInit(): void {
    this.refreshTable();
  }

  public refreshTable(): void {
    this.rolesManagementService.loadPaginatedRoles();
    this.rolesManagementService.countRolesFiltered();
  }

  getDateStringFromTimestamp(timestampString: number): any {
    if (timestampString) {
      return Utility.convertTimestampToStringDate(timestampString);
    } else {
      return '';
    }
  }

  deleteConfirmation(item: Role): void {
    this.modalService.createDialog('roles-management.delete-title',
      'roles-management.delete-question');
    this.modalService.onConfirm()
      .subscribe(
        _ => {
          this.deleteRole(item.id);
          this.modalService.close();
        });
    this.modalService.onCancel()
      .subscribe(
        _ => {
          this.modalService.close();
        });
    this.modalService.open();
  }

  public deleteRole(roleID: string): void {
    this.roleCtrl.deleteObject(Utility.getObjectId(roleID)).subscribe(() => {
        this.refreshTable();
        this.notificationBoxService.sendAlert(
          new Alert(AlertType.SUCCESS, this.translateService.translate('roles-management.role-deleted')));
      }
    );
  }


  updateRole(role: Role): void {
    this.roleCtrl.updateObject(role).subscribe(() => {
      this.refreshTable();
      this.notificationBoxService.sendAlert(
        new Alert(AlertType.SUCCESS, this.translateService.translate('roles-management.role-saved')));
    });
  }

  searchByName(data: any, columnName: string): void {
    this.filterValueChange(columnName, data.target.value, Object.keys(OperationSign).indexOf(OperationSign.LIKE.toString()).toString());
  }


  public filterValueChange(column: string, value: any, selectedFilter: string): void {
    this.rolesManagementService.searchCriteriaForClass = this.searchCriteriaService.updateSearchCriteria(
      this.rolesManagementService.searchCriteriaForClass, column, value, selectedFilter, false);
    this.refreshTable();
  }

  paginate(event: any): void {
    this.rolesManagementService.pageable.pageNumber = event.first / event.rows;
    this.rolesManagementService.pageable.pageSize = event.rows;
    this.rolesManagementService.loadPaginatedRoles();
  }

  sortByColumn(columnName: string): void {
    this.rolesManagementService.searchCriteriaForClass.sortBy = columnName;
    this.ascending = !this.ascending;
    this.rolesManagementService.searchCriteriaForClass.ascending = this.ascending;
    this.rolesManagementService.loadPaginatedRoles();
  }
}
