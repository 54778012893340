import {Component, OnInit} from '@angular/core';
import {languagesList} from './languages';
import {LanguageCtrl} from '../../core/sdk/translationmanager-controllers';
import {Language} from '../../core/sdk/model-translations';
import {Utility} from '../../shared/utilities/utility';

@Component({
  selector: 'app-language-management',
  templateUrl: './language-management.component.html',
  styles: []
})
export class LanguageManagementComponent implements OnInit {
  languageData: Language[];
  availableLanguages: Language[];
  values = Object.keys(languagesList);

  constructor(private languageCtrl: LanguageCtrl) {
  }

  ngOnInit(): void {
    this.languageData = [];
    this.availableLanguages = [];
    this.values.forEach(value => {
      this.languageData.push({localeName: languagesList[value], locale: value} as Language);
    });
    this.reloadPage();
  }

  reloadPage(): void {
    this.languageCtrl.findAll().subscribe(value => {
      this.availableLanguages = value;
    });
  }

  addNew(language): void {
    this.languageCtrl.createNewLanguage(language).subscribe(_ => {
      this.reloadPage();
    });
  }

  removeLanguage(language: Language): void {
    this.languageCtrl.deleteObject(Utility.getObjectId(language.id)).subscribe(_ => this.reloadPage());
  }
}
