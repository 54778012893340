<p-dropdown
  *ngIf="languages"
  [options]="languages" (onChange)="onSelect($event)" [(ngModel)]="translateService.currentLanguage">
  <ng-template let-language pTemplate="selectedItem">
    <div>{{ language.localeName }}</div>
  </ng-template>
  <ng-template let-language pTemplate="item">
    <div>
      <div>{{ language.localeName }}</div>
    </div>
  </ng-template>
</p-dropdown>
