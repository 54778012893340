import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LoginCtrl, PersonCtrl} from '../sdk/translationmanager-controllers';
import {Functionality, Person, Role} from '../sdk/translationmanager-model';
import {SessionService} from '../../shared/services/session.service';
import {TranslateService} from '../translations/translate.service';
import {NotificationBoxService} from '../../shared/services/notification-box.service';
import {Utility} from '../../shared/utilities/utility';
import {PersonLogin} from '../sdk/model-dto';

@Injectable()
export class AuthService {
  private authToken: string;
  private loggedUser: Person;
  functionalities: Functionality[] = [];
  currentFunctionality: Functionality;

  constructor(private router: Router,
              private loginCtrl: LoginCtrl,
              private notificationBoxService: NotificationBoxService,
              private personCtrl: PersonCtrl) {

  }

  login(username: string, password: string): void {
    this.loginWithRedirect(username, password, '');
  }

  loginWithRedirect(username: string, password: string, url: string): void {
    SessionService.clearSession();
    this.loginCtrl.login(this.getPersonLogin(username, password)).subscribe((response) => {
        if (response.token) {
          this.setAuthToken(response.token);
          this.setLoggedPerson(response.person);
          this.functionalitiesInit();
          this.navigate(url);
        }
      }, (err) => Utility.showViolationsIfOccurs(err, this.notificationBoxService)
    );
  }

  sessionLogin(): void {
    this.saveSessionInApp();
    this.functionalitiesInit();
  }

  functionalitiesInit(): void {
    this.populateUserFunctionalities();
    // this.setCurrentFunctionality();
    // this.updateDefaultLanguageForPerson();
  }

  navigate(url: string): void {
    if (url !== '') {
      this.router.navigateByUrl(url);
    } else {
      this.router.navigate(['/tm']);
    }
  }

  isAuthenticated(): boolean {
    const token = SessionService.getToken();
    const person = SessionService.getPerson();
    return token !== '' && !!person.id;
  }

  getCurrentAuthToken(): string {
    return this.authToken ? this.authToken : SessionService.getToken();
  }

  logout(): void {
    this.authToken = '';
    SessionService.clearSession();
    this.router.navigate(['/login']);
  }

  saveSessionInApp(): void {
    this.authToken = SessionService.getToken();
    this.loggedUser = SessionService.getPerson();
  }

  setAuthToken(token: string): void {
    SessionService.setToken(token);
    this.authToken = token;
  }

  setLoggedPerson(person: Person): void {
    SessionService.setPerson(person);
    this.loggedUser = person;
  }

  getPersonLogin(login: string, pwd: string): PersonLogin {
    return {
      password: pwd,
      username: login
    };
  }

  getCurrentPersonRoles(): Role[] {
    return this.getCurrentUser().roles;
  }

  getCurrentUser(): Person {
    return this.loggedUser ? this.loggedUser : SessionService.getPerson();
  }

  populateUserFunctionalities(): Functionality[] {
    const functionalitiesSet: Set<Functionality> = new Set<Functionality>();
    const userRoles = this.getCurrentPersonRoles();
    if (userRoles) {
      this.functionalities = [];
      userRoles.forEach(role => {
        role.functionalities.forEach(functionality => {
          functionalitiesSet.add(functionality);
        });
      });
      functionalitiesSet.forEach(functionality => {
        this.functionalities.push(functionality);
      });
    }

    return this.functionalities;
  }

  getUserFunctionalities(): Functionality[] {
    return this.functionalities;
  }

  // setCurrentFunctionality(newFunctionality?: Functionality): void {
  //   if (newFunctionality) {
  //     this.currentFunctionality = newFunctionality;
  //     this.translateService.addFunctionalities(newFunctionality.name);
  //   } else if (this.getCurrentPersonRoles() &&
  //     this.getCurrentPersonRoles()[0] &&
  //     this.getCurrentPersonRoles()[0].functionalities[0]) {
  //     this.currentFunctionality = this.getCurrentPersonRoles()[0].functionalities[0];
  //     this.translateService.addFunctionalities(this.getCurrentPersonRoles()[0].functionalities[0].name);
  //   }
  // }

  // updateDefaultLanguageForPerson(): void {
  //   if (this.translateService.getCurrentLanguage()?.locale !== this.getCurrentUser()?.defaultLanguage) {
  //     this.translateService.setLanguageForLocale(this.getCurrentUser().defaultLanguage);
  //   }
  // }


  // doUpdateDefaultLanguageForPerson(): void {
  //   if (this.getCurrentUser()) {
  //     this.personCtrl.updateDefaultLanguageForPerson(Utility.getObjectId(this.getCurrentUser().id),
  //       this.translateService.currentLanguage.locale).subscribe(
  //       data => this.loggedUser = data);
  //   }
  // }

}
