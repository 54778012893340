import {Injectable} from '@angular/core';
import {SecurityCtrl} from '../sdk/translationmanager-controllers';

@Injectable()
export class PasswordResetService {

  constructor(private securityCtrl: SecurityCtrl) {
  }

  // TODO error and success handling
  public passwordReset(email: string): void {
    this.securityCtrl.resetPassword(email)
      .subscribe(() => {
      });
  }
}
