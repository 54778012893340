import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Alert} from '../../alert';
import {NotificationBoxService} from '../../../services/notification-box.service';
import Timer = NodeJS.Timer;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit, AfterViewInit {
  @Input() alert: Alert;
  @Input() key: number;
  @Input() timeToLive: number;
  public isFirst: boolean;

  timer: Timer;
  private notificationTTLOffset: number;

  constructor(private notificationBoxService: NotificationBoxService) {
    this.notificationTTLOffset = 500;
  }

  ngOnInit(): void {
    this.startTimer();
    this.isFirst = true;
  }

  ngAfterViewInit(): void {
    this.notificationBoxService.notifyEmit();
  }

  startTimer(): void {
    this.timer = setTimeout(
      () => this.deleteNotification(),
      this.timeToLive
    );
  }

  stopTimer(baseTTL: number): void {
    clearTimeout(this.timer);
    this.timeToLive = this.timeToLive - baseTTL;
  }

  deleteNotification(): void {
    this.notificationBoxService.deleteNotification(this.key);
  }
}
