import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseEntity} from './core-model';
import {NewPassword, PasswordChange, PersonLogin, PersonLoginInfo, SearchCriteriaForClass, Version} from './model-dto';
import {EmailKey, EmailTemplate, Language, TranslationKey, TranslationModule} from './model-translations';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Address, Functionality, Group, Person, Role, Security, Theme} from './translationmanager-model';
import {LanguageDTO, TranslationKeyDTO} from './translations-dto';
/* tslint:disable  max-line-length  align  eofline  no-trailing-whitespace  typedef-whitespace  whitespace   */

@Injectable()
export class AddressCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: BaseEntity): Observable<Address>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Address>();
    this.httpService.post('/api/address', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/address/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAll(): Observable<Address[]>  {
   const subject = new Subject<Address[]>();
    this.httpService.get('/api/address', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllAddressesWithConditions(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<any[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});

    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<any[]>();
    this.httpService.post('/api/address/address-conditions', JsonScopedSerializer.stringify(searchCriteria, new JsonScope(false, [])) , {headers, params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Address>  {
   const subject = new Subject<Address>();
    this.httpService.get('/api/address/' + id + '', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: BaseEntity): Observable<Address>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Address>();
    this.httpService.put('/api/address/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class EmailKeyCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(name: string): Observable<EmailKey>  {
    const headers = new HttpHeaders().set('Content-type', 'text/plain');
   const subject = new Subject<EmailKey>();
    this.httpService.post('/api/email-key', JsonScopedSerializer.stringify(name, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/email-key/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getEmailKeyPaginated(pageSize: number, pageNumber: number, name?: string): Observable<EmailKey[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    if (name !== undefined && name !== null) {
      queryParamsList.push({name: 'name', value: name});
    }

    queryParamsList.push({name: 'page-size', value: pageSize.toString()});

    queryParamsList.push({name: 'page-number', value: pageNumber.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<EmailKey[]>();
    this.httpService.get('/api/email-key/', {params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<EmailKey>  {
   const subject = new Subject<EmailKey>();
    this.httpService.get('/api/email-key/' + id + '', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class EmailTemplateCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public getObject(id: string): Observable<EmailTemplate>  {
   const subject = new Subject<EmailTemplate>();
    this.httpService.get('/api/email-template/' + id + '', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: BaseEntity): Observable<EmailTemplate>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<EmailTemplate>();
    this.httpService.put('/api/email-template/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ExportTranslationsCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public export(type: string, databaseType?: string): Observable<any>  {
    const queryParamsList: { name: string, value: string }[] = [];
    if (databaseType !== undefined && databaseType !== null) {
      queryParamsList.push({name: 'database-type', value: databaseType});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<any>();
    this.httpService.get('/api/export-translations/' + type + '', {params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class FunctionalityCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public findAll(): Observable<Functionality[]>  {
   const subject = new Subject<Functionality[]>();
    this.httpService.get('/api/functionalities', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getFunctionalitiesCountFiltered(searchCriteria: SearchCriteriaForClass): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post<number>('/api/functionalities/count-filtered', searchCriteria , {headers})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getFunctionalitiesFiltered(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Functionality[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});

    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Functionality[]>();
    this.httpService.post('/api/functionalities/filter', JsonScopedSerializer.stringify(searchCriteria, new JsonScope(false, [])) , {headers, params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Functionality>  {
   const subject = new Subject<Functionality>();
    this.httpService.get('/api/functionalities/' + id + '', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class GroupCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countAllGroups(): Observable<number>  {
   const subject = new Subject<number>();
    this.httpService.get<number>('/api/group/count')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: BaseEntity): Observable<Group>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Group>();
    this.httpService.post('/api/group', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/group/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAll(): Observable<Group[]>  {
   const subject = new Subject<Group[]>();
    this.httpService.get('/api/group', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllGroupsWithConditions(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<any[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});

    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<any[]>();
    this.httpService.post('/api/group/groups-conditions', JsonScopedSerializer.stringify(searchCriteria, new JsonScope(false, [])) , {headers, params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Group>  {
   const subject = new Subject<Group>();
    this.httpService.get('/api/group/' + id + '', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public paginatedFindAll(pageNumber: number | null, pageSize: number | null): Observable<Group[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    if (pageNumber !== undefined && pageNumber !== null) {
      queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<Group[]>();
    this.httpService.get('/api/group', {params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: BaseEntity): Observable<Group>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Group>();
    this.httpService.put('/api/group/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class LanguageCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createNewLanguage(language: LanguageDTO): Observable<Language>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Language>();
    this.httpService.post('/api/language/', JsonScopedSerializer.stringify(language, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/language/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAll(): Observable<Language[]>  {
   const subject = new Subject<Language[]>();
    this.httpService.get('/api/language', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class LoginCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public getCurrentUser(): Observable<Person>  {
   const subject = new Subject<Person>();
    this.httpService.get('/api/login/user', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getCurrentUserRoles(): Observable<Role[]>  {
   const subject = new Subject<Role[]>();
    this.httpService.get('/api/login/roles', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public login(personLogin: PersonLogin): Observable<PersonLoginInfo>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<PersonLoginInfo>();
    this.httpService.post('/api/login', JsonScopedSerializer.stringify(personLogin, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class PersonCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countAllPeople(): Observable<number>  {
   const subject = new Subject<number>();
    this.httpService.get<number>('/api/person/countAllPeople')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: BaseEntity): Observable<Person>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Person>();
    this.httpService.post('/api/person', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createPerson(person: Person): Observable<Person>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Person>();
    this.httpService.post('/api/person/create', JsonScopedSerializer.stringify(person, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/person/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deletePerson(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/person/deletePerson/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAll(): Observable<Person[]>  {
   const subject = new Subject<Person[]>();
    this.httpService.get('/api/person', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllPeople(pageNumber: number, pageSize: number): Observable<Person[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});

    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<Person[]>();
    this.httpService.get('/api/person/getAllPeople', {params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Person>  {
   const subject = new Subject<Person>();
    this.httpService.get('/api/person/' + id + '', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getPeopleCountFiltered(searchCriteria: SearchCriteriaForClass): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post<number>('/api/person/count-filtered', searchCriteria , {headers})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getPeopleFiltered(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Person[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});

    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Person[]>();
    this.httpService.post('/api/person/filter', JsonScopedSerializer.stringify(searchCriteria, new JsonScope(false, [])) , {headers, params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public selfUpdate(person: Person): Observable<Person>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Person>();
    this.httpService.put('/api/person/self-update', JsonScopedSerializer.stringify(person, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public update(person: Person): Observable<Person>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Person>();
    this.httpService.put('/api/person/update', JsonScopedSerializer.stringify(person, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateDefaultLanguageForPerson(personId: string, defaultLanguageLocale: string): Observable<Person>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'default-language', value: defaultLanguageLocale});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Person>();
    this.httpService.put('/api/person/update-language/' + personId + '', null , {headers, params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: BaseEntity): Observable<Person>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Person>();
    this.httpService.put('/api/person/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class RoleCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: BaseEntity): Observable<Role>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Role>();
    this.httpService.post('/api/roles', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/roles/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAll(): Observable<Role[]>  {
   const subject = new Subject<Role[]>();
    this.httpService.get('/api/roles', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Role>  {
   const subject = new Subject<Role>();
    this.httpService.get('/api/roles/' + id + '', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getRolesCountFiltered(searchCriteria: SearchCriteriaForClass): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post<number>('/api/roles/count-filtered', searchCriteria , {headers})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getRolesFiltered(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Role[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});

    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Role[]>();
    this.httpService.post('/api/roles/filter', JsonScopedSerializer.stringify(searchCriteria, new JsonScope(false, [])) , {headers, params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getUserRoles(personId?: string): Observable<Role[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    if (personId !== undefined && personId !== null) {
      queryParamsList.push({name: 'personId', value: personId});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<Role[]>();
    this.httpService.get('/api/roles/user-roles', {params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: BaseEntity): Observable<Role>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Role>();
    this.httpService.put('/api/roles/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class SecurityCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public changePassword(passwordChange: PasswordChange): Observable<void>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<void>();
    this.httpService.put<void>('/api/security/change-password', passwordChange , {headers})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public newPassword(newPassword: NewPassword): Observable<void>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<void>();
    this.httpService.put<void>('/api/security/new-password', newPassword , {headers})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public resetPassword(email: string): Observable<void>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'email', value: email});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<void>();
    this.httpService.post<void>('/api/security/reset-password', null , {headers, params})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: BaseEntity): Observable<Security>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Security>();
    this.httpService.put('/api/security/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ThemeCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: BaseEntity): Observable<Theme>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Theme>();
    this.httpService.post('/api/theme', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/theme/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAll(): Observable<Theme[]>  {
   const subject = new Subject<Theme[]>();
    this.httpService.get('/api/theme', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getActiveTheme(): Observable<Theme>  {
   const subject = new Subject<Theme>();
    this.httpService.get('/api/theme/get-active-theme', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Theme>  {
   const subject = new Subject<Theme>();
    this.httpService.get('/api/theme/' + id + '', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public isCustomThemeAvailable(): Observable<boolean>  {
   const subject = new Subject<boolean>();
    this.httpService.get<boolean>('/api/theme/custom-theme-available')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: BaseEntity): Observable<Theme>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Theme>();
    this.httpService.put('/api/theme/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class TranslationKeyCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countFiltered(uiKey?: string, translationModuleId?: string, translationName?: string, isValid?: boolean): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    if (uiKey !== undefined && uiKey !== null) {
      queryParamsList.push({name: 'ui-key', value: uiKey});
    }

    if (translationModuleId !== undefined && translationModuleId !== null) {
      queryParamsList.push({name: 'translation-module', value: translationModuleId});
    }

    if (translationName !== undefined && translationName !== null) {
      queryParamsList.push({name: 'translation-name', value: translationName});
    }

    if (isValid !== undefined && isValid !== null) {
      queryParamsList.push({name: 'is-valid', value: isValid.toString()});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get<number>('/api/translation-key/count', {params})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createNewTranslationKey(translationKeyDTO: TranslationKeyDTO): Observable<void>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<void>();
    this.httpService.post<void>('/api/translation-key/', translationKeyDTO , {headers})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteTranslationKey(parameter: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/translation-key/' + parameter + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAllFiltered(pageSize: number, pageNumber: number, uiKey?: string, translationModuleId?: string, translationName?: string, orderBy?: string, asc?: boolean, isValid?: boolean): Observable<TranslationKey[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    if (uiKey !== undefined && uiKey !== null) {
      queryParamsList.push({name: 'ui-key', value: uiKey});
    }

    if (translationModuleId !== undefined && translationModuleId !== null) {
      queryParamsList.push({name: 'translation-module', value: translationModuleId});
    }

    if (translationName !== undefined && translationName !== null) {
      queryParamsList.push({name: 'translation_name', value: translationName});
    }

    if (orderBy !== undefined && orderBy !== null) {
      queryParamsList.push({name: 'order-by', value: orderBy});
    }

    if (asc !== undefined && asc !== null) {
      queryParamsList.push({name: 'asc', value: asc.toString()});
    }

    if (isValid !== undefined && isValid !== null) {
      queryParamsList.push({name: 'is-valid', value: isValid.toString()});
    }

    queryParamsList.push({name: 'page-size', value: pageSize.toString()});

    queryParamsList.push({name: 'page-number', value: pageNumber.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<TranslationKey[]>();
    this.httpService.get('/api/translation-key/', {params, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateTranslationKey(translationKey: TranslationKey): Observable<TranslationKey>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<TranslationKey>();
    this.httpService.put('/api/translation-key/', JsonScopedSerializer.stringify(translationKey, new JsonScope(false, [])) , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class TranslationModuleCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createNewTranslationModule(translationModule: string): Observable<TranslationModule>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<TranslationModule>();
    this.httpService.post('/api/translation-module/' + translationModule + '', null , {headers, responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteTranslationModule(uuid: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/translation-module/' + uuid + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAll(): Observable<TranslationModule[]>  {
   const subject = new Subject<TranslationModule[]>();
    this.httpService.get('/api/translation-module', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class VersionCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public getServerVersion(): Observable<Version>  {
   const subject = new Subject<Version>();
    this.httpService.get('/api/version', {responseType: 'text'}).pipe(map(res => JSON.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

